import { useMemo, useState , useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
// import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useSelector,useDispatch } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { address_showing, isEmpty } from "../../actions/common";
import config from "../config/config";
import { BuyAccept } from "../../actions/axioss/nft.axios";

import { getReferUserDetails } from "../../actions/axioss/user.axios";
import { network } from "../config/network";
import { on } from "process";
import { Decryptdata } from "../../actions/encryptkeys";
import { buyNFT,buyNftWithToken ,getTokenbalance} from '../hooks/useWallet'
export function BuyNow({ OpenPopup, closePop, owner, item ,onhide}) {
  const navigate = useNavigate();

  const { currency } = useSelector((state) => state.LoginReducer);
  const { web3, web3p, accountAddress, coinBalance ,decimals,tokenBalance} = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { Network } = useSelector((state) => state.LoginReducer)
  // console.log('coinnnnballl',coinBalance,web3p.utils.fromWei(coinBalance.toString()))
  const { buyerFees, sellerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );

  const ContractCall = useContractProviderHook();
  const dispatch = useDispatch()
  const push = useNavigate();
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [referredUser, setReferredUser] = useState({});
  const [Btn, SetBtn] = useState("start");
  console.log("buttonnss",Btn);
  const [App_Btn, SetApp_Btn] = useState(
    owner.CoinName != "SOL" ? "init" : "start"
  );
  console.log("fdgdfgggdf",App_Btn);
  const [Error, SetError] = useState("");
  const [NFTQuantity, SetNFTQuantity] = useState(1);
  const [TokenBalance, SetTokenBalance] = useState("0");
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => closePop();
  const [proceedtopayment, setProceedtopayment] = useState(false);
  const [once,setOnce]=useState(true)
  console.log("Oncce",once);
  const decimal = currency?.filter((item) => item.label === owner.CoinName)?.pop()?.decimal ??   18;
  const token_address = currency?.filter((item) => item.label === owner.CoinName)?.pop()?.address ??  config.DEADADDRESS;
  const YouWillGet = useMemo(() => {
    return owner.NFTPrice * NFTQuantity
    // ContractCall.buy_bid_price_calculation(
    //   (owner.NFTPrice * NFTQuantity).toString(),
    //   decimal.toString()
    // );
  }, [owner.TokenPrice, NFTQuantity]);
  const Validation = async () => {
    var error = {};
    if (isEmpty(NFTQuantity)) return "Token Quantity Required";
    else if (Number(owner.NFTBalance) < Number(NFTQuantity))
      return (error.NFTQuantity =
        "NFT Quantity should be less than " + owner.NFTBalance);
    if (
      owner.CoinName != "SOL" &&
      Number(owner.TokenPrice) * Number(NFTQuantity) > Number(TokenBalance)
    )
      return "Insufficient Balance";
   // else return await ContractCall.Contract_Base_Validation();
  };

  const FormSubmit = async () => {
    const id = toast.loading("Purchasing Token on processing");
    console.log("idsss",id);
    SetError("");
    SetBtn("process");
    var error = await Validation();
    console.log("after", error);
    if (error) {
      toast.update(id, {
        render: error,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");
      SetError(error);
    } else {
      console.log(
        "vaada",
        currency?.filter((item) => item.label === owner.CoinName)?.pop()
          ?.address
      );
        console.log("token_address",token_address,config.TradeContract,YouWillGet);
      let cont = await ContractCall.approve_721_1155(
        token_address,
        network[Network].tradeContract,
        web3p.utils.toWei(YouWillGet.toString())
      );
      console.log("cont", cont);
      if (cont) {
        toast.update(id, {
          render: "Approve Token Successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("done");
        SetApp_Btn("start");
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
    }
  };

  useEffect(() => {
    (async () => {
      let TokenBal = await getTokenbalance(token_address, accountAddress)
      console.log('====================================TokenBal',TokenBal);
      // console.log(TokenBal);
      // console.log('====================================');
      let accountDetails = {}
      accountDetails.accountAddress = accountAddress
      accountDetails.web3p = web3;
      accountDetails.coinBalance = coinBalance
      accountDetails.web3 = web3;
      accountDetails.tokenBalance = TokenBal?.tokenBalance ? TokenBal?.tokenBalance  : 0;
      accountDetails.decimals = TokenBal?.Decimal ? TokenBal?.Decimal : 0 ;
      dispatch({
        type: "Account_Section",
        Account_Section: { AccountDetails: accountDetails }
    })
      SetTokenBalance(TokenBal ? TokenBal : 0);
    })();
  }, [TokenBalance]);

  useEffect(() => {
   // getReferUser();
  }, [payload]);

  const getReferUser = async () => {
    if (!isEmpty(payload?.referedBy)) {
      var ressp = await getReferUserDetails({
        WalletAddress: payload?.referedBy,
      });
      console.log("resssp on getrefer", ressp);

      if (ressp?.success) setReferredUser(ressp?.data);
    }
  };

  // const _Buy = async () => {
  //   SetApp_Btn("process");
  //   console.log("ghgdhdg", referredUser,payload);
  //   const id = toast.loading("Purchasing Token on processing");
  //   var error = await Validation();
  //   console.log(
  //     "ghgdhdg errrrrrrrrr",
  //     web3p.utils.toWei(YouWillGet.toString()),
  //     owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName,
  //     owner.NFTOwner,
  //     [
  //       owner.NFTId,
  //       web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
  //       NFTQuantity,
  //       item.ContractType,
  //       web3p.utils.toWei(String(referredUser?.earnPercentage ?? 0)),
  //       web3p.utils.toWei(
  //         String(payload?.referedBy && payload?.initialBuy == false ? 4 : 0)
  //       ),
  //     ],
  //     [
  //       item.ContractAddress,
  //       payload?.referedBy ? payload?.referedBy : config.DEADADDRESS,
  //     ]
  //   );
  //   SetError(error);
  //   if (isEmpty(error)) {
  //     let cont = await ContractCall.buy_721_1155(
  //       web3p.utils.toWei(YouWillGet.toString()),
  //       owner.CoinName,
  //       owner.NFTOwner,
  //       [
  //         owner.NFTId,
  //         web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
  //         NFTQuantity,
  //         item.ContractType,
  //         web3p.utils.toWei(String(referredUser?.earnPercentage ?? 0)),
  //         web3p.utils.toWei(
  //           String(payload?.referedBy && payload?.initialBuy == false ? 4 : 0)
  //         ),
  //       ],
  //       [
  //         item.ContractAddress,
  //         payload?.referedBy ? payload?.referedBy : config.DEADADDRESS,
  //       ]
  //     );
  //     console.log("contcont", cont);
  //     if (cont) {
  //       let newOwner = {
  //         HashValue: cont.HashValue,
  //         NewTokenOwner: accountAddress,
  //         NFTQuantity: NFTQuantity,
  //         NFTId: owner.NFTId,
  //         NFTOwner: owner.NFTOwner,
  //         PutOnSale: owner.PutOnSale,
  //         PutOnSaleType: owner.PutOnSaleType,
  //         activity: "Buy",
  //         TP: owner.NFTPrice,
  //         New_EmailId : payload?.EmailId, 
  //         CN: owner.CoinName,
  //         click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`,
  //         initialBuy: payload?.initialBuy,
  //         referedBy: payload?.referedBy,
  //         earnPercentage: referredUser?.earnPercentage ?? 0,
  //         adminFeePercentage:
  //           Number(buyerFees) / 1e18 + Number(sellerFees) / 1e18,
  //       };
  //       let Resp = await BuyAccept({ newOwner: newOwner, item: item });
       
  //       if (Resp.success == "success") {
  //         toast.update(id, {
  //           render: "The NFT is successfully purchased",
  //           type: "success",
  //           isLoading: false,
  //           autoClose: 1000,
  //           closeButton: true,
  //           closeOnClick: true,
  //         });
  //         // SetApp_Btn("done");
  //         navigate(`/profile/${payload.CustomUrl}`, {
  //           state: { Tab: "owned" },
  //         });
  //         // if(payload?.initialBuy == false){
  //         //   var newPayload = payload
  //         //   newPayload.initialBuy = true
  //         //   dispatch({
  //         //     type: 'Register_Section',
  //         //     Register_Section: {
  //         //         User: {
  //         //             payload: newPayload
  //         //         }
  //         //     }
  //         // })
  //         // }
  //       } else {
  //         toast.update(id, {
  //           render: "Transaction Failed",
  //           type: "error",
  //           isLoading: false,
  //           autoClose: 1000,
  //           closeButton: true,
  //           closeOnClick: true,
  //         });
  //         SetApp_Btn("try");
  //       }
  //     } else {
  //       toast.update(id, {
  //         render: "Transaction Failed",
  //         type: "error",
  //         isLoading: false,
  //         autoClose: 1000,
  //         closeButton: true,
  //         closeOnClick: true,
  //       });
  //       SetApp_Btn("try");
  //     }
  //   } else {
  //     toast.update(id, {
  //       render: "Validation failed",
  //       type: "error",
  //       isLoading: false,
  //       autoClose: 1000,
  //       closeButton: true,
  //       closeOnClick: true,
  //     });
  //   }
  // };


  const _Buy = async () => {
    SetApp_Btn("process");
    const id = toast.loading("Purchasing Token on processing");
    var error = await Validation();
    // console.log(
    //   "ghgdhdg errrrrrrrrr",
    //   web3p.utils.toWei(YouWillGet.toString()),
    //   owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName,
    //   owner.NFTOwner,
    //   [
    //     owner.NFTId,
    //     web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
    //     NFTQuantity,
    //     item.ContractType,
    //     web3p.utils.toWei(String(referredUser?.earnPercentage ?? 0)),
    //     web3p.utils.toWei(
    //       String(userData?.referedBy && userData?.initialBuy == false ? 4 : 0)
    //     ),
    //   ],
    //   [
    //     item.ContractAddress,
    //     userData?.referedBy ? userData?.referedBy : Config.DEADADDRESS,
    //   ]
    // );
    let cont
    console.log("ownerlksdmflakmsd",owner,item);
    SetError(error);
    if (isEmpty(error)) {
      if(owner.CoinName == "SOL"){
       cont = await buyNFT(
        Decryptdata(owner.ScrectKey),
        owner?.NFTId,owner?.NFTOwner,
        accountAddress,web3,Number(YouWillGet),
        item.CollectionSymbol,
        buyerFees,
        NFTQuantity
      )
   }
    else{
      cont = await buyNftWithToken(
        Decryptdata(owner.ScrectKey),
        owner?.NFTId,owner?.NFTOwner,
        accountAddress,web3,Number(YouWillGet),decimals,NFTQuantity,
        item.CollectionSymbol,
        buyerFees
      )
    }
      // .buy_721_1155(
      //   web3p.utils.toWei(YouWillGet.toString()),
      //   owner.CoinName,
      //   owner.NFTOwner,
      //   [
      //     owner.NFTId,
      //     web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
      //     NFTQuantity,
      //     item.ContractType,
      //     web3p.utils.toWei(String(referredUser?.earnPercentage ?? 0)),
      //     web3p.utils.toWei(
      //       String(userData?.referedBy && userData?.initialBuy == false ? 4 : 0)
      //     ),
      //   ],
      //   [
      //     item.ContractAddress,
      //     userData?.referedBy ? userData?.referedBy : config.DEADADDRESS,
      //   ]
      // );
      console.log("contcont", cont);
      if (cont) {
        let newOwner = {
          HashValue: cont.HashValue,
          NewTokenOwner: accountAddress,
          NFTQuantity: NFTQuantity,
          NFTId: owner.NFTId,
          NFTOwner: owner.NFTOwner,
          PutOnSale: owner.PutOnSale,
          PutOnSaleType: owner.PutOnSaleType,
          activity: "Buy",
          TP: owner.NFTPrice,
          New_EmailId : payload?.EmailId, 
          CN: owner.CoinName,
          click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`,
          initialBuy: payload?.initialBuy,
          referedBy: payload?.referedBy,
          earnPercentage: referredUser?.earnPercentage ?? 0,
          adminFeePercentage:
            Number(buyerFees) / 1e18 + Number(sellerFees) / 1e18,
        };
        let Resp = await BuyAccept({ newOwner: newOwner, item: item });
       console.log("Respsdasd", Resp);
        if (Resp.success == "success") {
          toast.update(id, {
            render: "The NFT is successfully purchased",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          // SetApp_Btn("done");
          navigate(`/profile/${payload.CustomUrl}`, {
            state: { Tab: "owned" },
          });
          // if(payload?.initialBuy == false){
          //   var newPayload = payload
          //   newPayload.initialBuy = true
          //   dispatch({
          //     type: 'Register_Section',
          //     Register_Section: {
          //         User: {
          //             payload: newPayload
          //         }
          //     }
          // })
          // }
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetApp_Btn("try");
      }
    } else {
      toast.update(id, {
        render: "Validation failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };

  const onChange = (e) => {
    // console.log('vallll',e.target.value)
    var numberRegex = /^\d+$/;
      console.log('vallll',e.target.value)
      if(numberRegex.test(e.target.value) || e.target.value==""){
         SetNFTQuantity(e.target.value);SetError('');SetBtn('start');SetApp_Btn('init')
      }
      else{
         SetError("Token Quantity must be in number")
      }
  };

  useEffect(() => {
    // BalanceCheck();
  }, [item, owner]);

  async function BalanceCheck() {
    // SetBtn("process");
    // setOnce(false)
    if(once){
      console.log("Onnnn",once);
      let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("Balanance",owner,item)
      console.log("ownneerrsnftbusdasdynowbalittemmm", Nftbalance , owner?.NFTBalance , Nftbalance , owner?.NFTOwner , item.ContractType  , owner?.NFTOwner);
      
      if ((Number(Nftbalance) != Number(owner?.NFTBalance) &&  item.ContractType == '1155' ) || ( Nftbalance.toLowerCase() !=  owner?.NFTOwner.toLowerCase()  &&  item.ContractType == '721') ) {
       
  
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          push("/");
        }, 1000);
      }
     
    }
    SetBtn("start");
    return ()=>{ setOnce(false)}
    
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className={proceedtopayment ? "fixedmodel" : ""}
    >
      {/* {console.log('fgfdgfdhgfhb',NFTQuantity)} */}
      <Modal.Header>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>{closePop();
                         onhide()}}
        ></button>
        <Modal.Title id="contained-modal-title-vcenter">Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="buynowsec">
          <div className="buynowseller d-flex justify-content-between">
            <p className="receives">Seller :</p>
            <p className="receives">
              {owner.DisplayName ? owner.DisplayName : address_showing(owner.NFTOwner)}
            </p>
          </div>
          <div className="buynowseller d-flex justify-content-between">
            <p className="receives">Buyer:</p>
            <p className="receives">
              {payload?.DisplayName
                ? payload?.DisplayName
                : address_showing(payload.WalletAddress)}
            </p>
          </div>
        </div>
        <div className="buynowbalance mt-5">
          <div className="buynowseller  d-flex justify-content-between">
            <p className="receives">Your Balance :</p>
            <p className="receives">
              {console.log("coinbalaave",tokenBalance,coinBalance,owner.CoinName, coinBalance)}
              {(owner.CoinName != "SOL" && owner.CoinName != "SOL")
                ? tokenBalance?.toFixed(4)
                : coinBalance?.toFixed(4)}{" "}
              {owner.CoinName}
            </p>
          </div>
          {/* <div className='buynowseller d-flex justify-content-between'>
               <p>Your Token Balance:</p><p>99999.8 T CAKE</p></div> */}
            <div className='buynowseller  d-flex justify-content-between'>
               <p className="receives">Price :</p><p className="receives">{owner.NFTPrice} {owner.CoinName}</p></div>
            <div className='buynowseller  d-flex justify-content-between'>
               <p className="receives">Service Fee :</p><p className="receives">{buyerFees}% {owner.CoinName}</p></div> {/*{web3p.utils.fromWei(String(buyerFees))}*/ }
            <div className='buynowseller d-flex justify-content-between'>
               <p className="receives">Royalty Fee :</p><p className="receives">{(item.NFTRoyalty)}% {owner.CoinName}</p></div>
            <div className='buynowseller d-flex justify-content-between'>
               <p className="receives">You Will Pay :</p><p className="receives">{(Number(YouWillGet) + (Number(YouWillGet) * Number(buyerFees) / 100)).toFixed(6)} {owner.CoinName}</p></div>
               <p className="text-left pb-2 mt-4 receives">Enter Quantity <span>({owner.NFTBalance} available)</span></p>
        <input
          type="text"
          id="NFTQuantity"
          value={NFTQuantity}
          onChange={(e) => onChange(e)}
          name="NumOnly"
          className=""
          disabled={owner.NFTBalance == '1' || owner.NFTBalance == 1 ? true : false}
          placeholder="Enter your bid quantity"
        />
        {Error && ( <span className="text-danger img-file">{Error}</span>)}
         </div>
         <div className='connecwalletbtns d-flex justify-content-between mt-5'>
         {/* {
         <button className="tf-button kitty__loadMoreBtn flex-grow-1" tabIndex="-1" 
         disabled    =   {Btn == 'error' || Btn === "process"  ||  Btn ==="done" ? true : false} 
         onClick     =   {Btn == 'start' || Btn === "try" ? FormSubmit : null}>{Btn == 'start' && 'Approve' 
         ||Btn == 'try' && 'Try-Again'
         ||Btn == 'error' && 'Error' 
         ||Btn == 'done' && 'Done' 
         ||Btn == 'process' && 'In-Progress' 
          }</button>
         } */}
         <button className={ Btn ==="done" ? "cancelpayment secondary" : "connectwallet hides primary" } 
         tabIndex="-1" 
        // disabled    =   {Btn!='done' && App_Btn=='init'|| App_Btn == 'error' || App_Btn === "process"  ||  App_Btn ==="done" ? true : false} 
         onClick     =   {_Buy}>{App_Btn == 'start' && 'Proceed to pay' 
         ||App_Btn == 'try' && 'Try-Again'
         ||App_Btn == 'error' && 'Error' 
         ||App_Btn == 'done' && 'Done' 
         ||App_Btn == 'process' && 'In-Progress' 
         ||App_Btn == 'init' && 'Proceed to pay' 
          }</button>
            {/* <button className={proceedtopayment ? "connectwallet primary" : "connectwallet hides primary"} tabIndex="-1" aria-disabled="true" onClick={() => setProceedtopayment(true)}>Proceed</button>
            <button className='cancelpayment secondary' onClick={handleClose10}>Cancel</button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
