
import React, { useEffect, useState ,useMemo} from "react";
import "../assets/js/common.js";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import Copy from "../assets/images/svg/copy.svg";
import Profile from "../assets/images/profileicon.png";
import WalletIcon from "../assets/images/svg/wallet.svg";
import LogoutIcon from "../assets/images/svg/logout.svg";
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';
import { connectWallet, getServiceFees } from '../views/hooks/useWallet';
import { isEmpty } from '../actions/common';
import CopyToClipboard from "react-copy-to-clipboard";

import { RxPerson } from 'react-icons/rx';

import { userRegister, SearchAction, GetUserCookieToken } from '../actions/axioss/user.axios'
import { GetNftCookieToken } from '../actions/axioss/nft.axios'

import { Category, Currency, USDPRICE, TOKENPRICE } from '../actions/axioss/cms.axios'
import { useDispatch, useSelector } from 'react-redux';
import Config from "../views/config/config";
import { address_showing, copydata } from "../actions/common";

import users from "../assets/images/user.png";
import noimg from "../assets/images/noimage.png"
import { network } from "../views/config/network.js";
import { config } from "process";
import BeforeloginEditProfile from "../views/BeforeloginEditProfile.js";



// FINAL 
import {
    Connection,
    Keypair,
    // SystemProgram,
    LAMPORTS_PER_SOL,
    Transaction,
    clusterApiUrl,
    PublicKey,
    sendAndConfirmTransaction,
  } from "@solana/web3.js";

  import * as anchor from '@project-serum/anchor';
import idl from './idl.json';





// import { minnft } from "../actions/contract.js";


//solana 

//   import { clusterApiUrl, Connection, PublicKey , SystemProgram,Keypair, Transaction ,LAMPORTS_PER_SOL,sendAndConfirmTransaction} from "@solana/web3.js";

//   import {
//     createMint,
//     getOrCreateAssociatedTokenAccount,
//     mintTo,
//     createTransferInstruction,
//   } from "@solana/spl-token";
//   import * as web3 from "@solana/web3.js";
//   import * as anchor from '@project-serum/anchor'
//   import * as bs58 from "bs58";
//   import "@solana/wallet-adapter-react-ui/styles.css";
// import { minnft } from "../actions/contract.js";








export default function Header(props) { 

    const [menu, setMenu] = React.useState(false);
    const [arrow, setArrow] = React.useState(false);
    const [categorys, setCategory] = React.useState([]);

    const [Searchdata, SetSearch] = React.useState(null);

    const { Categorys } = useSelector((state) => state.LoginReducer);

  
    const [val, Setval] = useState("");
    
    const push = useNavigate()
    const [updatePage, setUpdatePage] = useState()
    const [dataa, setDataa] = useState({})
    let location = useLocation();
  
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
   
    const switchTheme = () => {
        if (localStorage.theme == "is_light") {
            localStorage.setItem("theme", "is_dark");
            document.body.classList.remove("is_dark");
            document.body.classList.add("is_light");
        }
        else {
            localStorage.setItem("theme", "is_light");
            document.body.classList.remove("is_dark");
            document.body.classList.add("is_light");
        }
    }

    const dispatch = useDispatch();
    const wallet = useSelector(state => state.LoginReducer.AccountDetails);
    console.log("Walladdress",wallet);
   
    const [searchbottom,setSearchBottom] = useState(false)
    const { payload, token } = useSelector(state => state.LoginReducer.User)
    console.log("User details", payload, wallet)
    const [reconnect,setReconnect]=useState(true)
    useEffect(() => {
        console.log("Reconn",reconnect,payload);
        if (localStorage.getItem("walletConnectType") && wallet?.accountAddress == '' && reconnect) {
            initialConnectWallet(localStorage.getItem("walletConnectType"))
           
            setReconnect(false)
        }
        // if(wallet?.accountAddress){
            getInitialSeviceFee();
        // }
        Categorys1();
        CurrencyList();

    },[]);

    
    useEffect(() => {
        if(window.solana){
            window.solana.on('accountChanged', (publicKey) => {
                if (publicKey) {
                    // Set new public key and continue as usual
                    console.log(`Switched to account ${publicKey.toBase58()}`);
                    handleAccountChange()
                } 
            });
            // window.solana.on('chainChanged', (publicKey) => {
            //     if (publicKey) {
            //         // Set new public key and continue as usual
            //         console.log(`Switched to account ${publicKey.toBase58()}`);
            //         handleAccountChange()
            //     } 
            // });
        }
        document.body.addEventListener('click', function () {
            // Code to execute when the body is clicked
            Setval(''); SetSearch(null)
        });
    }, [])

    // window.addEventListener('load', function () {
    //     // Add a click event listener to the body
        


    // });
    const Categorys1 = async () => {
        let Resp = await Category();
        if (Resp?.data) {
            var sendda = [];
            var data = (Resp.data || []).map((item) => {

                // console.log('mapppppp',item);
                if (item.hideShow !== "hidden") {
                    sendda.push({
                        label: item.name,
                        value: item.name,
                        description: item.description,
                    });
                }

            });
            dispatch({
                type: "Register_Section",
                Register_Section: {
                    Categorys: sendda,
                },
            });
        }
    };
    const CurrencyList = async () => {
        let Resp = await Currency();
        console.log("Resp@123currency", Resp?.msg);
        if (Resp?.msg) {
            var sen = [];
            var CURRECYDATA = await Promise.all(
                Resp?.msg[0]?.CurrencyDetails ||
                []?.map(async (data) => {
                    if (data.label == "BNB" || data.label == "ETH")
                        var USD = await USDPRICE(data.label);
                    else var USD = await TOKENPRICE(data.address);
                    sen.push({
                        value: data.value,
                        label: data.label,
                        address: data.address,
                        usd: USD ? USD : 0,
                        decimal: data.decimal,
                    });
                })
            );
         
            dispatch({
                type: "Register_Section",
                Register_Section: {
                    currency:  CURRECYDATA,
                },
            });
        }
    };

    const  tokentransfer = async () => {
        // Connect to cluster
        // const SOLANA_HOST = clusterApiUrl(Config.network,true)
        //   console.log('SOLANA_HOST-->',SOLANA_HOST)
        //   const connection = new anchor.web3.Connection(SOLANA_HOST)
        // console.log('connection-->',connection)
       
    
       
         


           


    //     const fromWallet = Keypair.fromSecretKey(
    //         bs58.decode(
    //           "2yaAPPeyaXageq8cQCkL9oWZ7WmFZKQDL8Rv5xuDYo5Uerdm6M67ARESQ9yFzT2SA2wetXwJSY6oNgxH9sYVUnKv"
    //         )
    //       );
    //     console.log('fromWallet-->',fromWallet)
       
    //     // Generate a new wallet to receive newly minted token
    //     const toWallet = Keypair.generate();
       
    //     // Create new token mint
    //     const mint = await createMint(
    //       connection,
    //       fromWallet,
    //       fromWallet.publicKey,
    //       null,
    //       9,
    //     );
    //    console.log('mint-->',mint)
    //     // Get the token account of the fromWallet Solana address, if it does not exist, create it
    //     const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
    //       connection,
    //       fromWallet,
    //       mint,
    //       fromWallet.publicKey,
    //     );
    //    console.log('fromTokenAccount-->',fromTokenAccount)
    //     //get the token account of the toWallet Solana address, if it does not exist, create it
    //     const toTokenAccount = await getOrCreateAssociatedTokenAccount(
    //       connection,
    //       fromWallet,
    //       mint,
    //       fromWallet.publicKey,
    //     );
    //    console.log('toTokenAccount-->',toTokenAccount)
    //     // Minting 1 new token to the "fromTokenAccount" account we just returned/created
    //    let mint1 =  await mintTo(
    //       connection,
    //       fromWallet,
    //       mint,
    //       fromTokenAccount.address,
    //       fromWallet.publicKey,
    //       1000000000, // it's 1 token, but in lamports
    //       [],
    //     );
    //    console.log('mint1-->',mint1)
        // Add token transfer instructions to transaction
    //     const transaction = new Transaction().add(
    //       createTransferInstruction(
    //         fromTokenAccount.address,
    //         toTokenAccount.address,
    //         fromWallet.publicKey,
    //         1,
    //       ),
    //     );
    //    console.log('transaction-->',transaction)
    //     // Sign transaction, broadcast, and confirm
    //    let confirm =  await sendAndConfirmTransaction(connection, transaction, [fromWallet]);
// console.log('confirm-->',confirm)
      }

//     const sendTransaction = async () => {
//         try {
//             const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
//           const transaction = new Transaction().add(
//             SystemProgram.transfer({
//               fromPubkey: new PublicKey(wallet.accountAddress),
//               toPubkey: new PublicKey('EZdKFDmasNqgWKiBjt9tj7BBKENjueoRCuYR8mD6t89Q'),
//               lamports: LAMPORTS_PER_SOL, // Amount to send (in lamports)
//             })
//           );
//     console.log('transaction-->',transaction)
//           transaction.feePayer = new PublicKey(wallet.accountAddress);
//           const { blockhash } = await connection.getRecentBlockhash();
//           transaction.recentBlockhash = blockhash;
//     console.log('blockhash-->',blockhash)
//           const signed = await window.solana.signTransaction(transaction);
//           console.log('signed-->',signed)
//           const signature = await connection.sendRawTransaction(signed.serialize());
//           console.log('signature-->',signature)
//           let signhash = await connection.confirmTransaction(signature);
// console.log('signhash-->',signhash)
//         } catch (err) {
//           console.error(err);
//         }
//       };

const getprovider =async () =>{
    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    const anchorProvider = new anchor.AnchorProvider(connection, window.solana, {
        preflightCommitment: 'processed',
      });
    //   setProvider(anchorProvider);
      const programID = new PublicKey(idl.metadata.address);
      const anchorProgram = new anchor.Program(idl, programID, anchorProvider);
      console.log('anchorProgram-->',anchorProgram)
    //   setProgram(anchorProgram);






    
        const message = `To avoid digital dognappers, sign below to authenticate with CryptoCorgis`;
    const encodedMessage = new TextEncoder().encode(message);
    const signedMessage = await window.solana.request({
        method: "signMessage",
        params: {
             message: encodedMessage,
             display: "hex",
        },
    });
    
    console.log('signedMessage-->',signedMessage,signedMessage?.signature?.toString())
      
  }

    const handleAccountChange = (...args) => {
        console.log("helllllllllo");
        const id = toast.success("Wallet Connecting...", { autoClose: 1000, isLoading: false })
        toast.update(id, { autoClose: 1000, isLoading: false, })
        initialConnectWallet(localStorage.walletConnectType);
    }
    const initialConnectWallet = async (type, chain) => {

        const id = ("Wallet Connecting...", { closeButton: true, closeOnClick: true })
        console.log("ennanadkkuthu",type);
        // if(!localStorage.getItem("accountInfo")){
        var accountDetails = await connectWallet(type)
        console.log("accountDetails", accountDetails)

        if (!isEmpty(accountDetails)) {
            // debugger
            var resp = await setWalletAddress('InitialConnect', accountDetails.accountAddress, type, id)
            console.log("resppppppppp", resp)
            if (resp?.success == 'success') {
                toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, })
           
                    // dispatch({
                    //     type: "Network_section",
                    //     Network_section: {
                    //         Network: chainid
                    //     },
                    // })
             

                dispatch({
                    type: "Account_Section",
                    Account_Section: { AccountDetails: accountDetails }
                })


            }
           

        }
        
        else toast.update(id,{render: `${type} wallet not recognised`, type:'error',autoClose:1000, isLoading: false,})
    }
    const setWalletAddress = async (type, walletAddress, walletType, id) => {
        if (walletAddress) {
            var NewMethod = {
                Type: type,
                WalletAddress: walletAddress,
                WalletType: walletType,
            };
            //   console.log("Resp.data1",NewMethod);
            let Resp = await userRegister(NewMethod);
            console.log("Resp.data", Resp.data);
            if (Resp?.already && Resp?.data?.DisplayName != "") {
                if (Resp?.success == 'success') {
                    dispatch({
                        type: 'Register_Section',
                        Register_Section: {
                            User: {
                                payload: Resp?.data,
                                token: Resp?.token ? Resp.token : token
                            }
                        }
                    })
                    document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
                    GetNftCookieToken();
                    GetUserCookieToken();
                    return Resp
                }
                else return Resp
            } else {
                toast.update(id, { render: "Create Profile First", type: 'success', autoClose: 1000, isLoading: false, })
                if (!isEmpty(payload)) {
                    dispatch({
                        type: 'Register_Section',
                        Register_Section: {
                            User: {
                                token: "",
                                payload: null
                            }
                        }
                    })
                }
                localStorage.removeItem("accountInfo")
                localStorage.removeItem("walletConnectType")
                dispatch({
                    type: 'Account_Section',
                    Account_Section: {
                        AccountDetails: {
                            accountAddress: "",
                            tokenBalance: 0,
                            coinBalance: 0
                        }
                    }
                })
                push(`/createProfile/${walletAddress}`)
                // Resp.data.initial = true
                // setDataa(Resp.data)
                // setUpdatePage(true)

            }
        }
        else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

    }
    const walletDisconnect = async () => {
        // localStorage.removeItem("accountInfo")
        // localStorage.removeItem("walletConnectType")
        if(window.solana){
            window.solana.disconnect()
        }
        localStorage.clear()
        dispatch({
            type: 'Account_Section',
            Account_Section: {
                AccountDetails: {
                    accountAddress: "",
                    tokenBalance: 0,
                    coinBalance: 0
                }
            }
        })
        push("/")
        document.cookie = 'token' + "=" + "" + ";" + ";path=/";
        GetNftCookieToken();
        GetUserCookieToken();
    }
    const getInitialSeviceFee = async () => {
        var fees = await getServiceFees();
        console.log("getServiceFees",fees);
        if (fees) {
            dispatch({
                type: 'ServiceFees',
                ServiceFees_Section: {
                    ServiceFees: fees,
                },
            });
        }
    };
    const click = async (table) => {
        push("/explore/" + table)
    }


    const OnChange = async (value) => {
        if (value) {
            Setval(value);
            var Resp = await SearchAction({

                keyword: value,
                limit: 3,
                page: 1,
                from: "search",
            });
            if (Resp?.success === "success") {
                SetSearch(Resp);
            }
            else {
                SetSearch(null)
            }
        }
        else {
            SetSearch(null)
            Setval('')
        }
    };

    const Search = () => {
        if (window.location.pathname.includes('search')) {
            SetSearch(null)
            Setval('')
        }

    }

    const [isScrolled,setIsScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          if (scrollPosition > 100) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (
        <>
            <header className={`header py-3 ${isScrolled && 'header__scrolled py-3'}`}>
                {menu == true ?
                    (<nav id="main-nav-mobi" className="main-nav d-block">
                        <ul id="menu-primary-menu" className="menu">
                            {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}
                            <li className="menu-item search-item">
                                <form action="#">
                                    <div className="search-form d-flex justify-content-between align-items-center">
                                        <input type="text" placeholder="Search" required="" onChange={(e) => {OnChange(e.target.value);setSearchBottom(true)}} autoComplete="off" />
                                        <Link className="btn-search">
                                            {!val && <i className="icon-fl-search-filled"></i>}
                                            {val && <i className="fa fa-times" onClick={() => { Setval(''); SetSearch(null) }}></i>}
                                            {/* <i className="fa fa-times"></i> */}
                                        </Link>
                                        <div className={ searchbottom ? "search-model": "search-model kr_align"} >
                                            {val && (
                                                <div className="contentTop" id="smc-modal">
                                                    {Searchdata?.token?.data?.length > 0 ||
                                                        Searchdata?.user?.msg?.length > 0 || Searchdata?.collection?.msg?.length > 0 ? (<>
                                                            {Searchdata?.collection?.data?.length > 0 && (
                                                                <div className="content">
                                                                    {/* <h6>Collections</h6> */}
                                                                    {Searchdata?.collection?.data?.map(
                                                                        (value) => (<>
                                                                            {/* <div className="d-flex align-items-center justify-content-start pt-4 searchCursor" onClick={() => {
                                                                        Setval("");
                                                                      
                                                                    }}
                                                                    >
                                                                       <div className="">
                                                                       <img
                                                                                        src={
                                                                                            value.CollectionProfileImage
                                                                                                ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                                                                : require("../assets/images/noimage.png")
                                                                                        }
                                                                                        
                                                                                     alt="Search" className="img-fluid"/>
                                                                       </div>
                                                                       <div className="ml-3">
                                                                        <p>{value.CollectionName}</p>
                                                                       
                                                                        
                                                                        </div>
                                                                    </div> */}
                                                                        </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                            {Searchdata.user?.msg?.length > 0 && (

                                                                <div className="content">
                                                                    <h6>Users</h6>
                                                                    {Searchdata.user?.msg.map(
                                                                        (value) => (
                                                                            <>
                                                                                <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                    onClick={() => {
                                                                                        Setval("");
                                                                                        push(
                                                                                            `/profile/${value.CustomUrl}`
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <div className="">
                                                                                        <img
                                                                                            src={
                                                                                                value.Profile
                                                                                                    ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                                                                    : Config.profile
                                                                                            }
                                                                                            className="img"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                        <p >
                                                                                            {value.DisplayName}
                                                                                        </p>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}

                                                            {Searchdata.token?.data?.length > 0 && (
                                                                <div className="content">
                                                                    <h6>Tokens</h6>
                                                                    {Searchdata.token?.data.map(
                                                                        (value) => (<>
                                                                            <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                onClick={() => {
                                                                                    Setval("");
                                                                                    SetSearch();
                                                                                    push(
                                                                                        `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div className="">
                                                                                    {console.log("COMPRESED", value.CompressedFile)}
                                                                                    <img src={value.CompressedFile.includes('.webp') || value.CompressedFile.includes('.png') ? value.CompressedFile.split(':')[0] == 'https' ? value.CompressedFile : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}` : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`} alt="Search" className="img-fluid" />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <p>  {value.NFTName}</p>
                                                                                    <span></span>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        )
                                                                    )}
                                                                </div>

                                                            )}
                                                            <div className="tf-button search-button my-5 d-flex justify-content-center">
                                                                <Link to={(`/search/${val}`)}>Search</Link>
                                                                {/* <Link to="/">Search</Link> */}
                                                            </div>
                                                        </>) : (val === '' ? <></>
                                                            : <span className="error_msg">
                                                                No data Found
                                                            </span>
                                                    )}
                                                </div>
                                            )}
                                            {/* <ul className="sub-menu d-block">
                                                <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search<i class="far fa-long-arrow-right"></i></Link></div>
                                            </ul> */}
                                        </div>
                                    </div>
                                </form>
                            </li>
                            <li className="menu-item menu-item-has-children">
                                <Accordion defaultActiveKey="0" className="menu-sub">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header> <NavLink to="/explore">Explore</NavLink></Accordion.Header>
                                        <Accordion.Body>

                                            <ul className="sub-menu d-block">
                                                <li className={"menu-item"} onClick={() => { click("All") }}><Link to="#">All</Link></li>
                                                {Categorys.map(item => <li className={"menu-item"} onClick={() => { click(item.value) }}><Link to="#">{item.label}</Link></li>)}
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </li>
                            {console.log("locationpath" ,location.pathname.includes("createProfile") , location.pathname )}
                           { location.pathname.includes("createProfile") || location.pathname.includes("connect") ? <></> :
                            <li className="menu-item">
                                <NavLink to="/create">Create</NavLink>
                            </li>}
                            
                            <li className="menu-item">
                                <NavLink to="/faq">FAQ</NavLink>
                            </li>
                            {wallet && wallet.accountAddress ?
                                <>
                                    <li className="menu-item">
                                        <NavLink to={`/profile/${payload?.CustomUrl}`}>Profile</NavLink>
                                        {/* <NavLink to="/">Profile</NavLink>                                              */}
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="#" onClick={() => { walletDisconnect() }}>Disconnect</NavLink>
                                        {/* <NavLink to="#" >Disconnect</NavLink>                                      */}
                                    </li>
                                </>
                                :
                                <li className="menu-item">
                                    {/* {walletbutton()} */}
                                    <NavLink to="/connect">Connect Wallet</NavLink>
                                </li>
                            }

                        </ul>
                    </nav>)
                    : (<></>)
                }
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="site-header-inner">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <NavLink to="/" rel="home" className="main-logo">
                                            {/* <p id="logo_header"></p> */}
                                            <img src={require('../assets/images/kitty/logo.png')} className="img-fluid kitty__headerLogo" />
                                        </NavLink>
                                    </div>
                                    {/* <div>
                                        <p>BETA</p>
                                    </div> */}
                                </div>
                                <div className="header-center">
                                    <nav id="main-nav" className="main-nav">
                                        <ul id="menu-primary-menu" className="menu">
                                            {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}


                                           
                                            <li className="menu-item menu-item-has-children ">
                                                <NavLink to="/explore">Explore</NavLink>
                                                <ul className="sub-menu">
                                                    <li className={"menu-item"} onClick={() => { click("All") }}><Link to="#">All</Link></li>

                                                    {Categorys.map(item => <li className={"menu-item"} onClick={() => { click(item.value) }}><Link to="#">{item.label}</Link></li>)}

                                                </ul>

                                            </li>
                                            {wallet && wallet.accountAddress &&
                                            <li className="menu-item">
                                                <NavLink to="/create">Create</NavLink>
                                            </li>}
                                            {/* <li className="menu-item">
                                                <div onClick={getprovider}>getpr</div> 
                                                
                                            </li> */}
                                            <li className="menu-item">
                                                <NavLink to="/faq">FAQ</NavLink>
                                            </li>
                                            {wallet && wallet.accountAddress &&
                                                <li className="menu-item">

                                                    <NavLink to={`/profile/${payload?.CustomUrl}`}>Profile</NavLink>

                                                </li>
                                            }



                                        </ul>
                                    </nav>
                                </div>

                                <div className="header-right d-flex justify-content-end align-items-center">

                                <li className="menu-item search-item kitty__headerSearchWeb">
                                                <form action="#">
                                                    <div className="search-form d-flex justify-content-between align-items-center">
                                                        <input type="text" value={val} placeholder="Search" required="" autoComplete="off" onChange={(e) => {OnChange(e.target.value);setSearchBottom(true)}} />
                                                        <Link className="btn-search">
                                                            {!val && <i className="icon-fl-search-filled"></i>}
                                                            {val && <i className="fa-solid fa-xmark" onClick={() => { Setval(''); SetSearch(null) }}></i>}
                                                        </Link>
                                                        {/* <ul className="sub-menu d-block">
                                                        <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                        <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search <i class="far fa-long-arrow-right"></i></Link></div>
                                                    </ul> */}
                                                    </div>

                                                    <div className={searchbottom && searchbottom ? "search-model" : "search-model kr_align"}>
                                                        {val && (
                                                            <div className="contentTop">
                                                                {Searchdata?.token?.data?.length > 0 ||
                                                                    Searchdata?.user?.msg?.length > 0 || Searchdata?.collection?.msg?.length > 0 ? (<>
                                                                        {Searchdata?.collection?.data?.length > 0 && (
                                                                            <div className="content">
                                                                                {/* <h6>Collections</h6> */}
                                                                                {Searchdata?.collection?.data?.map(
                                                                                    (value) => (<>
                                                                                        {/* <div className="d-flex align-items-center justify-content-start pt-4 searchCursor" onClick={() => {
                                                                        Setval("");
                                                                        push(
                                                                            `/collection/${value.CollectionSymbol}`
                                                                        );
                                                                    }}
                                                                    >
                                                                       <div className="">
                                                                       <img
                                                                                        src={
                                                                                            value.CollectionProfileImage
                                                                                                ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                                                                : require("../assets/images/noimage.png")
                                                                                        }
                                                                                        
                                                                                     alt="Search" className="img-fluid"/>
                                                                       </div>
                                                                       <div className="ml-3">
                                                                        <p>{value.CollectionName}</p>
                                                                    
                                                                        
                                                                        </div>
                                                                    </div> */}
                                                                                    </>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {Searchdata.user?.msg?.length > 0 && (

                                                                            <div className="content">
                                                                                <h6>Users</h6>
                                                                                {Searchdata.user?.msg.map(
                                                                                    (value) => (
                                                                                        <>
                                                                                            <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                                onClick={() => {
                                                                                                    Setval("");
                                                                                                    push(
                                                                                                        `/profile/${value.CustomUrl}`
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <div className="">
                                                                                                    <img
                                                                                                        src={
                                                                                                            value.Profile
                                                                                                                ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                                                                                :  Config.profile
                                                                                                        }
                                                                                                        className="img"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="ml-3">
                                                                                                    <p >
                                                                                                        {value.DisplayName}
                                                                                                    </p>
                                                                                                    <span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {Searchdata.token?.data?.length > 0 && (
                                                                            <div className="content">
                                                                                <h6>Tokens</h6>
                                                                                {Searchdata.token?.data.map(
                                                                                    (value) => (<>
                                                                                        <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                            onClick={() => {
                                                                                                Setval("");
                                                                                                SetSearch();
                                                                                                push(
                                                                                                    `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <div className="">

                                                                                                <img src={value.CompressedFile.includes('.webp') || value.CompressedFile.includes('.png') ? value.CompressedFile.split(':')[0] == 'https' ? value.CompressedFile : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}` : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`} alt="Search" className="img-fluid" />

                                                                                            </div>
                                                                                            <div className="ml-3">
                                                                                                <p>  {value.NFTName}</p>
                                                                                                <span></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>
                                                                                    )
                                                                                )}
                                                                            </div>

                                                                        )}
                                                                        <div className="tf-buton search-button my-5 d-flex justify-content-center">
                                                                            {/* <Link to="/">Search</Link>                                                         */}
                                                                            <Link to={`/search/${val}`} onClick={Search}>Search</Link>
                                                                        </div>
                                                                    </>) : (val === '' ? <></>
                                                                        : <span className="error_msg">
                                                                            No data Found
                                                                        </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                </form>
                                            </li>
                                    {wallet && wallet.accountAddress ?
                                        <NavLink to="#" className="tf-button tf-connect kitty__headerGradBtn" onClick={() => { walletDisconnect() }}><span>Disconnect</span></NavLink>
                                        :
                                        // <>
                                        // {walletbutton()}
                                        // </>
                                        <NavLink to="/connect" className="tf-button tf-connect co-wa kitty__headerGradBtn mr-0"><span>Connect Wallet</span></NavLink>
                                    }
                                    {/* <NavLink to="/connect" className="tf-button tf-connect"><span>Connect Wallet</span></NavLink> */}
                                    {
                                        wallet && wallet.accountAddress &&
                                        <div className="popup-user">
                                            <div className="avatar">
                                                <img className="img-fluid imgprofile" width="20" height="18" src={payload?.Profile ? Config.IMG_URL + "/user/" + payload?.WalletAddress + '/profile/' + payload?.Profile : Config.profile} alt="avatar" />
                                            </div>
                                            <div className="avatar_popup">
                                                {payload && payload.msg && payload.msg.DisplayName &&
                                                    <h6>{payload.msg.DisplayName}</h6>
                                                }

                                                <div className="price">

                                                    <p className="title">Balance</p>
                                                    {
                                                        wallet && wallet.coinBalance ?
                                                            <p className="style">{wallet && wallet.coinBalance } {Config.COIN_NAME}</p>
                                                            :
                                                            <p className="style">0 {Config.COIN_NAME}</p>
                                                    }

                                                </div>
                                                {wallet && wallet.accountAddress && <>
                                                    {localStorage.getItem("walletConnectType") === 'Coinbase' ? <p className="title">Trust Wallet</p> : <p className="title">{localStorage.getItem("walletConnectType")}</p>}
                                                    <div className="code">
                                                        <p>{address_showing(wallet.accountAddress)}</p>
                                                        <CopyToClipboard
                                                            text={wallet?.accountAddress}
                                                            onCopy={() =>
                                                                toast.success("Address Copied", { autoClose: 1000 })
                                                            } >

                                                            <img src={Copy} alt="Images" />

                                                            {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
                                                        </CopyToClipboard>

                                                    </div>
                                                </>}
                                                {/* <ul className="links"> */}

                                                    {/* <li>
                                                        <NavLink to={`/profile/${payload?.CustomUrl}`}>
                                                            <img src={Profile} alt="images" />
                                                           
                                                            &emsp;<span>My Profile</span>
                                                        </NavLink>
                                                    </li> */}

                                                    {/* <li>
                                                    <NavLink to="/connect" className="mt-10">
                                                    <img src={WalletIcon} alt="images" />
                                                    &emsp;<span>Wallet</span>
                                                    </NavLink>
                                                </li> */}
                                                    {/* <li>
                                                    {wallet && wallet.accountAddress ?
                                                    <NavLink to="#" className="mt-10" id="logout" onClick={()=>{walletDisconnect()}}>
                                                    <img src={LogoutIcon} alt="images" />
                                                        &emsp;<span>Log out</span>
                                                    </NavLink>
                                                    :
                                                    <NavLink to="/connect" className="mt-10" id="logout">
                                                    <img src={LogoutIcon} alt="images" />
                                                        &emsp;<span>Log in</span>
                                                    </NavLink>
                                                    }
                                                </li> */}
                                                {/* </ul> */}
                                            </div>
                                        </div>
                                    }
                                    {/* <Link to="#" className="mode-switch" onClick={switchTheme}>
                                       <p className="icon-change"></p>
                                    </Link> */}
                                </div>
                                <div className={menu == true ? "mobile-button active" : "mobile-button"} id="mobile-button" onClick={() => setMenu(!menu)}><span></span></div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {updatePage && <>
                <section className="tf-page-title" data-aos="fade-left">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    {/* <li>Create profile</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <BeforeloginEditProfile valData={dataa} />
            </>}
        </>
    );
}