import React, { useState, useEffect, useMemo } from "react";
import MetaMask from "../assets/images/svg/metamask.svg";
import Upload from "../assets/images/uploadimg.png";
import ETH from "../assets/images/ethe.svg";
import Wallet3 from "../assets/images/walletconnect.png";
import Wallet4 from "../assets/images/TWT.png";
import createitems from "../assets/images/createitemm.png";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { NavLink, useLocation } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-datetime/css/react-datetime.css";
import { useSelector, useDispatch } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import config from "./config/config";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { getcurrency, isEmpty } from "../actions/common";
import { ImgValidation } from "../actions/validations";
import { CollectionByCreator, nftCreate } from "../actions/axioss/nft.axios";
import { createImg } from "../actions/axioss/user.axios";
import {
  nftNameValidation,
  NFTImageUpload,
  CreateNFT,
} from "../actions/axioss/nft.axios";
import { useNavigate, Link } from "react-router-dom";
import CreateCollection from "./seperatemodals/CreateCollection";
import moment from "moment";
import Select from "react-select";
import CLockModal from "../views/seperatemodals/ClockModal";
import { switchnetwork } from "../actions/common";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "../separate/RadialSeparators";
import preview from "../assets/images/default_preview1.png";
import endless from "../assets/images/endless.png";
import product52 from "../assets/images/product52.jpg";
import imageprev from "../assets/images/imgpreview.png";
import { network } from "./config/network";
import { MintNFT , nftDelegateApprove1} from "./hooks/useWallet.js"



var Formavaludata;



export default function Create() {

  const location1 = useLocation();
  const dispatch = useDispatch();
  const [loading, setloading] = useState();
  const { sellerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  const { web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const { payload } = useSelector(
    (state) => state.LoginReducer.User
  );
  // const { Network } = useSelector( (state) => state.LoginReducer );

  // const { payload , token } = useSelector(state => state.LoginReducer.User)
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [collection, setCollection] = React.useState(1);
  const [progressValue, setProgressValue] = useState(0)

  //prevent image saving
  // useEffect(() => {
  const preventContextMenu = (event) => {
    event.preventDefault();
  };



  let renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const push = useNavigate();

  const [aitext, setAitext] = useState('')
  const { currency, Categorys } = useSelector((state) => state.LoginReducer);
  console.log('currrencccyy', currency, Categorys)
  const initialTokenValue = {
    NFTName: "",
    NFTQuantity: 1,
    NFTOrginalImage: "",
    NFTOrginalImagePreview: "",
    NFTThumpImage: "",
    NFTThumpImagePreview: "",
    NFTOrginalImageIpfs: "",
    NFTThumpImageIpfs: "",
    CompressedFile: "",
    CompressedThumbFile: "",
    NFTDescription: "",
    PutOnSaleType: "UnlimitedAuction",
    PutOnSale: false,
    NFTPrice: "",
    NFTMinimumBid: "",
    ClockTime: "",
    EndClockTime: "",
    NFTRoyalty: "",
    NFTProperties: [],
    NFTCreator: accountAddress,
    NFTOwner: accountAddress,
    HashValue: "",
    MetFile: "",
    MetaData: "",
    ContractAddress: "",
    ContractType: "",
    Category: Categorys.length > 0 && Categorys[0].label,
    CoinName: "SOL",
    UnlockContent: "",
    CollectionName: "KITTY",
    CollectionNetwork: config.COIN_NAME,
    Chain_ID: config.CHAIN_ID ,
    CollectionSymbol: "",
    isMessageapprove: "",
    isPricenotification: "",
    isPromotion: "",
    islegalalert: "",
    EmailId : payload?.EmailId
  };

  const [checked, setChecked] = useState(true)
  const [NFTFormValue, setNFTFormValue] = useState(initialTokenValue);

  const [NFTFormValuedata, setNFTFormValuedata] = useState(initialTokenValue);

  const [ValidateError, SetValidateError] = useState({});
  const [FormButton, SetFormButton] = useState("start");
  const [ApproveButton, SetApproveButton] = useState("start");
  const [UploadButton, SetUploadButton] = useState("stop");
  const [MintButton, setMintButton] = useState("stop");
  const [theme, setTheme] = useState(false);
  const [show8, setShow8] = useState(false);
  const [location, SetLocation] = useState("");
  const [OpenPopup, SetOpenPopup] = useState("");
  const [CreateCollectionState, SetCreateCollectionState] = useState([]);
  const [aiurl, setAiurl] = useState('')
  const [listforsalenow, setlistforsalenow] = useState(false);
  const [list, setlist] = useState("timed_auction");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [number, setNumber] = useState([0]);
  const [key, setKey] = useState({});
  const [Value, setValue] = useState({});
  const ContractCall = useContractProviderHook();
  const options = [
    { value: "BNB", label: "BNB" },
    { value: "WBNB", label: "WBNB" },
  ];
  console.log("CHAINIDINCREATE", NFTFormValue.Chain_ID)
 

  const startingdate = [
    { value: "List Immediately", label: "List Immediately" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const enddate = [
    { value: "1 day", label: "1 day" },
    { value: "2 days", label: "2 days" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const handleClose8 = () => setShow8(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {

    // debugger;
    let path = collection == 1 ? "Single" : "Multiple";
    SetLocation(path);
    let type = collection == 1 ? 721 : 1155;
    let Addr = collection == 1 ? config?.TradeContract : config?.TradeContract
    console.log("NFTFORMSinnnnn" , type , Addr , collection );


    setNFTFormValue({
      ...NFTFormValue,
      ...{  
        ["ContractAddress"]: Addr,   ["ContractType"]: type, ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress,
      },
    });
 
    CollectionByCreate({
      Type: collection == 1 ? 721 : 1155,
      Creator: accountAddress,
    });



  }, [collection, location, accountAddress ]);

 
console.log("NFTFORMS",NFTFormValue);
  const DateSelection = (e, data) => {
    try {

      if (data == "start") {
        if (e.value == "List Immediately")
          setNFTFormValue({
            ...NFTFormValue,
            ...{
              ["ClockTime"]: new Date(),
            },
          });
        else if (e.value == "Scheduled Listing") SetOpenPopup("ClockTime");
      } else {
        if (e.value == "1 day") {
          console.log('dateeeee', new Date(new Date(NFTFormValue.ClockTime).setDate(new Date(NFTFormValue.ClockTime).getDate() + 1)))
          if (NFTFormValue.ClockTime === "") {
            setNFTFormValue({
              ...NFTFormValue,
              ...{
                ["EndClockTime"]:
                  new Date(new Date().setDate(new Date().getDate() + 1))

              },
            });
          }
          else {
            setNFTFormValue({
              ...NFTFormValue,
              ...{
                ["EndClockTime"]:
                  new Date(new Date(NFTFormValue.ClockTime).setDate(new Date(NFTFormValue.ClockTime).getDate() + 1))

              },
            });
          }
        } else if (e.value == "2 days") {

          if (NFTFormValue.ClockTime === "") {
            setNFTFormValue({
              ...NFTFormValue,
              ...{
                ["EndClockTime"]:
                  new Date(new Date().setDate(new Date().getDate() + 2))

              },
            });
          }
          else {
            setNFTFormValue({
              ...NFTFormValue,
              ...{
                ["EndClockTime"]:
                  new Date(new Date(NFTFormValue.ClockTime).setDate(new Date(NFTFormValue.ClockTime).getDate() + 2))

              },
            });
          }
        }
        else if (e.value == "Scheduled Listing") SetOpenPopup("EndClockTime");
      }
    }
    catch (err) {
      console.log("calerr", err);
    }
    console.log('datttteeeee', e.value, data, e.value == "Scheduled Listing")

  };

  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(NFTFormValue.NFTPrice);
  }, [NFTFormValue.NFTPrice]);
  const setClockValue = (data, date) => {
    console.log("setClockValue", data, date);
    setNFTFormValue({
      ...NFTFormValue,
      ...{ [data]:  new Date(date)}
        // moment(date).format("YYYY-MM-DD h:mm:ss a") },
    });


  };

  const CollectionByCreate = async (data) => {
    // console.log('fhbddghshgdh',data)
    data.from = "create"
    let Resp = await CollectionByCreator(data);
    SetCreateCollectionState(Resp?.data);
  };

  // input field onchange function
  const onChange = (e, acceptedfile, type) => {
    // if(){

    // }
    // console.log('ffgdvgfdaf',accountAddress)
    if (accountAddress) {
      SetFormButton("start");
      SetValidateError({})
      if (e && e.target) {
        const { files, value, id, name } = e.target;
        if (id == "NFTRoyalty" || id == "NFTPrice" || id == "NFTMinimumBid") {
          const checkprice = /^\d*\.?\d*$/;
          if (checkprice.test(value)) setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
        }
        else {
          setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
        }
        // console.log("form data", id, value);
      }
      if (acceptedfile) {
        console.log("Fileeeeeeee", acceptedfile[0], "type", type);
        // var reader = new FileReader()
        var file = acceptedfile[0];
        var validExtensions = [
          "png",
          "gif",
          "webp",
          "mp4",
          "PNG",
          "jpg",
          "JPEG",
          "jpeg",
          "JPG",
          "mp3",
          "aac",
          "AAC",
          "flac",
          "FLAC",
          "WEBM",
          "webm",
          "ogv",
          "OGV",
        ];
        var validExtensionsthump = [
          "png",
          "webp",
          "jpg",
          "jpeg"
        ];
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
  
        if (!validExtensions.some((val) => fileNameExt === val)) {
          toast.error(
            "Only these file types are accepted : " + validExtensions.join(", ")
          );
        }
        if (type == "Orginal") {
          console.log("original", acceptedfile[0]);
          setNFTFormValue({
            ...NFTFormValue,
            ...{ ["NFTOrginalImage"]: acceptedfile[0] },
          });
        }
        if (type == "Thump") {
          
          if (!validExtensionsthump.some((val) => fileNameExt === val)) {
            toast.error(
              "Only these file types are accepted : " + validExtensionsthump.join(", ")
            );
          } else {
            setNFTFormValue({
              ...NFTFormValue,
              ...{ ["NFTThumpImage"]: acceptedfile[0] },
            });
          }
      
        }
      }
    }
    else {
      toast.error("Connect Wallet To create NFT");
    }
  };

  //NFT mint validation function
  const Validation = async (data) => {
    let ValidateError = {};
    const {
      NFTName,
      NFTOrginalImage,
      NFTOrginalImageai,
      NFTThumpImage,
      NFTPrice,
      EndClockTime,
      ClockTime,
      NFTRoyalty,
      Category,
      PutOnSaleType,
      PutOnSale,
      CoinName,
      NFTQuantity,
      ContractType,
    } = data;
    // console.log(
    //   "sfgh",
    //   NFTOrginalImage,
    //   NFTOrginalImage.type?.includes("video")
    // );
    if (!NFTRoyalty) ValidateError.NFTRoyalty = "Royalty Required";
    else if (isEmpty(NFTRoyalty))
      ValidateError.NFTRoyalty = "Royalty Must Be Greater Than 0";
    else if (isNaN(NFTRoyalty) === true)
      ValidateError.NFTRoyalty = "Royalty must be a number";
    else if (Number(NFTRoyalty) < 0) ValidateError.NFTRoyalty = "Royalty must be Greater than 0"
    else if (Number(NFTRoyalty) > 20) ValidateError.NFTRoyalty = "Royalty Must be less than 20";
    else if (Number(NFTRoyalty) % 1 !== 0) ValidateError.NFTRoyalty = "Royalty must be a Whole Number"
    if (!NFTName) ValidateError.NFTName = "TokenName Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      isEmpty(NFTPrice)
    )
      ValidateError.NFTPrice = "NFTPrice Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      !CoinName && PutOnSale == true
    )
      ValidateError.CoinName = "CoinName Required";
    if (PutOnSaleType == "TimedAuction" && !ClockTime)
      ValidateError.ClockTime = "ClockTime Required";
    if (PutOnSaleType == "TimedAuction" && !EndClockTime)
      ValidateError.EndClockTime = "EndClockTime Required";

    if (PutOnSaleType == "TimedAuction") {
      if (ClockTime > EndClockTime || ClockTime.toString() == EndClockTime.toString()) {
        ValidateError.EndClockTime = "Time Auction should not be same";
      }

      if (new Date(ClockTime) > new Date(EndClockTime)) {
        ValidateError.EndClockTime = "Please Enter valid End Time"
      }
    }

    if (!Category) ValidateError.Category = "Category Required";
    console.log("jhsdjghsdhkgsouttt", NFTOrginalImage)


    if (!checked) {
      if (!NFTOrginalImageai) {
        ValidateError.NFTOrginalImageai = "Please generate image and mint"
      }

    }
    else {

      if (!NFTOrginalImage) {
        ValidateError.NFTOrginalImage = "OriginalFile Required";
      }
      if (ImgValidation(NFTOrginalImage, "pro"))
        ValidateError.NFTOrginalImage = ImgValidation(NFTOrginalImage, "pro");
      if (
        (NFTOrginalImage?.type?.includes("video") ||
          NFTOrginalImage?.type?.includes("audio")) &&
        !NFTThumpImage
      )
        ValidateError.NFTThumpImage = "ThumbFile Required";
      else if (NFTThumpImage)
        if (ImgValidation(NFTThumpImage, "thumb"))
          ValidateError.NFTThumpImage = ImgValidation(NFTThumpImage, "thumb");

    }



    if (isNaN(NFTPrice) === true)
      ValidateError.NFTPrice = "NFT Price Should Be a Number";
    else if (Number(NFTPrice) <= 0 && PutOnSale == true)
      ValidateError.NFTPrice = "NFTPrice should be above Zero";
    if (ContractType === 1155 || ContractType === '1155') {
      if ((Number(NFTQuantity) % 1) != 0) {
        ValidateError.NFTQuantity = '"Quantity" must be a Valid number';
      }
    }

    console.log('isEmptyyyy', key[number.length - 2], number.length - 1, key[number.length - 1], isEmpty(key[number.length - 1]), isEmpty(Value[number.length - 1]))
    if (Object.values(key)?.length > 0 || Object.values(Value)?.length > 0) {
      for (var i = 0; i < number.length; i++) {
        if ((isEmpty(key[i]))) {
          if (isEmpty(Value[i])) { }
          else {
            ValidateError['key'] = "Enter Key";
          }
        }
        if (isEmpty(Value[i])) {
          if (isEmpty(key[i])) { }
          else {
            ValidateError['value'] = "Enter Value";
          }
        }
      }
    }

    console.log('ksgfdkhgvfg',ValidateError,ContractType,(Number(NFTQuantity) % 1) !== 0,NFTQuantity)
    return ValidateError;
  };
  //NFT Form submit function
  const FormSubmit = async () => {
    SetValidateError({});
    const id = toast.loading("Validating Form");
    var Error = await Validation(NFTFormValue);
    console.log("Form error", Error);
    if (isEmpty(Error)) {
      var checkarr = [];
      if (Object.values(key)?.length > 0) {
        for (var i = 0; i < number.length; i++) {
          if (!isEmpty(key[i]) && !isEmpty(Value[i])) {
            NFTFormValue.NFTProperties.push({ [key[i]]: Value[i] })
            checkarr.push({ [key[i]]: Value[i] })
          }
        }
      }
      // console.log("valssss",number.length, NFTFormValue,checkarr);
      SetFormButton("process");
      let Resp = await nftNameValidation({
        NFTName: NFTFormValue.NFTName,
      });
      console.log('resppppp', Resp)

      if (Resp?.success == "success") {
        toast.update(id, {
          render: Resp?.success == "success" ? "Ready To Mint" : "Check Fields",
          type: Resp?.success,
          isLoading: false,
          autoClose: 1000,
        });
        let Respc = await ContractCall.Contract_Base_Validation();
        console.log('rsepccc', Respc)
        if (!Respc) {

          let Statu = true
          // await ContractCall.GetApproveStatus(
          //   location,
          //   NFTFormValue?.ContractAddress
          // );
          console.log("Approve status", Statu, location, NFTFormValue?.ContractAddress);
          if (Statu == true) {
            SetApproveButton("stop");
            SetUploadButton("start");
            toast.update(id, {
              render: "Start Minting",
              type: "success",
              isLoading: false,
              autoClose: 1000, closeButton: true, closeOnClick: true
            });
          } else {
            SetApproveButton("start");
            toast.update(id, {
              render: "Get Approve",
              type: "success",
              isLoading: false,
              autoClose: 1000, closeButton: true, closeOnClick: true
            });
          }
          setShow8(true);
        } else {
          SetFormButton("error");
          SetValidateError(Respc);
        }
      }
      else {
        toast.update(id, {
          render: "NFT Name Already Available Use Different Name",
          type: "error",
          isLoading: false,
          autoClose: 500, closeButton: true, closeOnClick: true
        });
        SetFormButton("error");
        SetValidateError({ NFTName: Resp.msg });
      }
    } else {
      SetFormButton("error");
      SetValidateError(Error);
      toast.update(id, {
        render: "Form Validation failed Check Fields",
        type: "error",
        isLoading: false,
        autoClose: 500, closeButton: true, closeOnClick: true
      });
    }
  };

  //NFT Initial Approve
  const TokenApproveCall = async () => {
    SetApproveButton("process");
    const id = toast.loading("Approve in process");
    console.log("databeforeapprove" , location ,   NFTFormValue?.ContractAddress)
    const cont = await ContractCall.SetApproveStatus(
      location,
      NFTFormValue?.ContractAddress
    );
    console.log("contcontcont", cont);
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000, closeButton: true, closeOnClick: true
    });
    if (cont?.status) {
      SetApproveButton("done");
      SetUploadButton("start");
    } else SetApproveButton("try");
  };







  //Upload image in IPFS
  async function UploadIPFScall() {
    const {
      NFTCreator,
      NFTThumpImage,
      NFTOrginalImage,
      NFTOrginalImageai,
      NFTName,
      NFTDescription,
      NFTProperties
    } = NFTFormValue;
    SetUploadButton("process");
    const id = toast.loading("Uploading  File");
 
    var Resp;

   
    if (!checked) {
     
      console.log("nft ai", NFTOrginalImage)
      Resp = await NFTImageUpload({
        'ai': true,
        NFTCreator: NFTCreator ? NFTCreator : accountAddress,
        NFTThumpImage,
        NFTOrginalImage: NFTOrginalImageai,
        NFTName,
        NFTDescription,
        NFTProperties: JSON.stringify(NFTProperties)
      });

    }
    else {
      console.log("Vanakkkam");
      Resp = await NFTImageUpload({
        NFTCreator: NFTCreator ? NFTCreator : accountAddress,
        NFTThumpImage,
        NFTOrginalImage,
        NFTName,
        NFTDescription,
        NFTProperties: JSON.stringify(NFTProperties)
      });
    }
  
    if (Resp?.success == "success") {
      setNFTFormValue({ ...NFTFormValue, ...Resp.data });
      SetUploadButton("done");
      setMintButton("start");
    } else {
      SetUploadButton("try");
    }
    toast.update(id, {
      render: Resp?.msg,
      type: Resp?.success,
      isLoading: false,
      autoClose: 1000, closeButton: true, closeOnClick: true
    });
  }

  //NFT Mint Function
  async function MintCall() {
    const id = toast.loading("Minting Processing");
    console.log("Minting Process", NFTFormValue, new Date(NFTFormValue.ClockTime));
    setMintButton("process");
    var _data = NFTFormValue;
    // console.log("NFTFormValue", NFTFormValue);
    _data.activity = "Mint";
    _data.NFTPrice == 0 ? _data.NFTPrice = '' : _data.NFTPrice = _data.NFTPrice;

   // let ENc = window.btoa(JSON.stringify(_data));
console.log("sdfgghdfg",NFTFormValue.NFTName,web3);

    const cont = await MintNFT(
      config.IPFS + NFTFormValue.MetaData,
      NFTFormValue.NFTName,
      NFTFormValue?.NFTRoyalty,
      NFTFormValue.NFTQuantity,
      web3);
console.log("fghngfh",config.IPFS + NFTFormValue.MetaData,NFTFormValue.NFTName,NFTFormValue?.NFTRoyalty,NFTFormValue.NFTQuantity,web3);

    // console.log("datainmincontractcall" , cont)
    if (cont) {
      if (NFTFormValue.PutOnSaleType === "TimedAuction") {
        _data.ClockTime = new Date(NFTFormValue.ClockTime);
        _data.EndClockTime = new Date(NFTFormValue.EndClockTime)
      }
      _data.HashValue = cont?.HashValue;
      _data.CollectionSymbol = cont?.CollectionSymbol
      _data.NFTId = cont?.tokenCounts;
      _data.click = `${config.FRONT_URL}/info/${NFTFormValue?.CollectionNetwork
        }/${NFTFormValue?.ContractAddress
        }/${accountAddress}/${cont?.tokenCounts}`;
      if (NFTFormValue.PutOnSaleType == "FixedPrice") {
        let Details = {
          NFTId: cont?.tokenCounts,
          NFTOwner: accountAddress,
          provider : web3
        }
        const ApproveData = await nftDelegateApprove1(Details)
        if(ApproveData.ScrectKey){
        console.log('ListApprove',ApproveData);
        _data.ScrectKey = ApproveData.ScrectKey
        let Resp = await CreateNFT(_data);
        toast.update(id, {
          render: Resp?.msg,
          type: Resp?.success,
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
        });
        console.log('mintcallincreate' , Resp)
        if (Resp?.success == "success") {
          setMintButton("done");
          toast.update(id, {
            render: "The NFT is successfully minted",
            type: "success",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
          push(`/profile/${payload?.CustomUrl}`, { state: { Tab: 'owned' } });
          // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
          setMintButton("try");
        }
        }else{
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
        });
        setMintButton("try");
        }
      }else{
        let Resp = await CreateNFT(_data);
        toast.update(id, {
          render: Resp?.msg,
          type: Resp?.success,
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
        });
        console.log('mintcallincreate' , Resp)
        if (Resp?.success == "success") {
          setMintButton("done");
          toast.update(id, {
            render: "The NFT is successfully minted",
            type: "success",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
          push(`/profile/${payload?.CustomUrl}`, { state: { Tab: 'owned' } });
          // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
          setMintButton("try");
        }
      }
    } else {
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000, closeButton: true, closeOnClick: true
      });
      setMintButton("try");
    }
  }


  useEffect(() => {
    Formavaludata=NFTFormValue

  }, [NFTFormValue])
console.log("NFTFormValueNFTFormValue",Formavaludata);


 

  const CurrecyChange = () => {

    let type = collection == 1 ? 721 : 1155;
    let Addr = collection == 1 ? config?.singleContract : config?.multipleContract
    console.log("NFTFORMSinnnnn" , type , Addr );
    if (NFTFormValue.PutOnSaleType === "FixedPrice")

      setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: currency[0]?.label , ["ContractAddress"]: Addr,   ["ContractType"]: type,   ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress, } })
    else if (NFTFormValue.PutOnSaleType === "TimedAuction")
      setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: currency[1]?.label, ["ContractAddress"]: Addr,   ["ContractType"]: type,   ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress, } });
    else
      setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: "", ["ContractAddress"]: Addr,   ["ContractType"]: type,  ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress, } });
  }

  const DiscardAll = () => {
    setNFTFormValue(initialTokenValue);
    // NFTPrice.val('')
  }

  const PutOnSale = () => {
    // CurrecyChange();
    if (NFTFormValue.PutOnSale === false) {
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["PutOnSale"]:
            !NFTFormValue.PutOnSale,
          ["PutOnSaleType"]: "FixedPrice"
        },
      })
    }
    else {
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["PutOnSale"]:
            !NFTFormValue.PutOnSale,
          ["PutOnSaleType"]: "UnlimitedAuction",
          ["NFTPrice"]: 0,
          ["NFTMinimumBid"]: 0,
          ["ClockTime"]: "",
          ["EndClockTime"]: "",
        },
      })
    }
  }
  const CloseModal = () => {
    SetOpenPopup("")
  }
  var validStart = function (current) {
    var yesterday = new Date()
    return current.isAfter(yesterday);
  }
  var validEnd = function (current) {
    return current.isAfter(NFTFormValue.ClockTime ? new Date(NFTFormValue.ClockTime) : undefined);
  }
  var Properties = (e) => {
    var { value, id } = e.target
    SetFormButton("start");
    SetValidateError({})
    if (id.includes("key")) {
      setKey({ ...key, ...{ [number.length - 1]: value } })
    } else if (id.includes("value")) {
      setValue({ ...Value, ...{ [number.length - 1]: value } })
    }
  }

  var AddProperties = () => {
    var error = {};
    if (isEmpty(key[number.length - 1])) {
      error.key = "Enter Key";
    }
    if (isEmpty(Value[number.length - 1])) {
      error.value = "Enter Value";
    }
    if (number.length <= 9) {
      if (isEmpty(error)) {
        document.getElementById("key").value = "";
        document.getElementById("value").value = "";
        setNumber([...number, number.length]);
      }
      else {
        SetValidateError(error);
      }
    }

  }

  var RemoveProperties = (ind) => {
    var check = delete Value[ind];
    delete (key[ind])
    delete Value[ind];
    var obj = {};
    var obj2 = {}
    for (var i = 0; i < number.length - 1; i++) {
      if (i >= ind) {
        if (Value[i + 1]) obj[i] = Value[i + 1];
        if (key[i + 1]) obj2[i] = key[i + 1];
      }
      else {
        obj2[i] = key[i]
        obj[i] = Value[i]
      }
    }
    setValue(obj);
    setKey(obj2);
    console.log('checkkkk', check, ind, obj)
    document.getElementById("key").value = "";
    document.getElementById("value").value = "";
    setNumber(number.filter((val, ind) => { return ind !== number.length - 1 }));
  }

  


  const setNetwork = async (e) => {
    SetFormButton("process")
   
    console.log("Network", e)
    if(accountAddress){
     
      var data = await switchnetwork(e, "switched")
      console.log("datwwda", data);
      if (data.status == true) {
        let formData = NFTFormValue;
        formData.CollectionNetwork = network[e]?.currencySymbol
        formData.Chain_ID = e
        formData.ContractAddress = collection == 1 ? network[e]?.singleContract : network[e]?.multipleContract
        formData.CoinName = formData.CoinName == "SOL" ? "SOL" : "SOL"
        console.log("AFTERCHANGECHAIN", formData)
        setNFTFormValue(formData)
        dispatch(data.data)
        dispatch(data.currency)
        setTimeout(() => {
          SetFormButton("start")  
        }, 2500);
  

      } else {
        toast.error(data.msg)
      }
    }
    else{
      toast.warn("Please connect Wallet")
    }
   

  }



  var WalletCheck = (index) => {
    if (payload?.WalletAddress) {
      SetOpenPopup("collection")
    }
    else {
      toast.error("Connect Wallet to Create Collection");
    }

  }

  const ChooseCollection = (symbol, category) => {
    if (NFTFormValue.CollectionSymbol !== symbol) {
      setNFTFormValue({ ...NFTFormValue, ...{ ["CollectionSymbol"]: symbol, ["Category"]: category, }, })
    }
    else {
      setNFTFormValue({ ...NFTFormValue, ...{ ["CollectionSymbol"]: "", ["Category"]: Categorys.length > 0 && Categorys[0].label } })
    }
  }

  const aicreate = async () => {
    setProgressValue(0)
    setloading(true)
    if (aitext) {
      var promt = { "promt": aitext }
      var num = 0;
      const progress = () => {
        if (num < 100) {
          num = num + 10
          setProgressValue(num)
        }
        else {
          clearInterval(id)
        }

      }



      let id = setInterval(progress, 6000);
      var resp = await createImg(promt)
      console.log("sssssssddfsss",Formavaludata);

      if (resp?.status) {
        console.log('====================================');
        console.log(resp.src);
        console.log('====================================');
        setloading(false)
        // if (progressValue == 100) {
          setAiurl(resp.src)
          setNFTFormValue({
            ...Formavaludata,
            ...{ ["NFTOrginalImageai"]: resp.src},
            // resp.src 
          })
        // }

        // setAiurl(resp.src)
        // setNFTFormValue({
        //   ...NFTFormValue,
        //   ...{ ["NFTOrginalImageai"]: resp.src },
        // })
      } else {
        toast.warning("OOPs Somthing Error")
      }
      console.log(resp, resp?.data, "image vantucha")

    }

    else {
      toast.warning("Please Enter a Prompt")
    }
  }

  const geturl = async (id) => {
    var progress;
    var retdata;

    do {



      //   var configs = {
      //     method: 'get',
      //     url: `https://api.thenextleg.io/v2/message/${id}`,
      //     headers: { 
      //       'Authorization': 'Bearer 014ad4ad-d99f-42a3-8539-d61da39d8d99', 
      //     },
      //   };

      //  var data= await axios(configs)
      //  console.log("fffdfd",data)
      //  progress=data.data.progress
      //    setAiurl(data.data.progressImageUrl)
      //    retdata=data.data


      // axios(configs).then((response)=>{
      //   console.log("dataaa",response.data);
      //   progress=response.data.progress
      //   setAiurl(response.data.progressImageUrl)
      //   retdata=response.data
      // })
      // if(data){
      //   if(Number(data.data.progress) != 100){
      //     geturl(id)
      //    }
      //    else{
      //     return retdata
      //    }
      // }





      var resp = await createImg({ id: id })
      progress = resp.data.progress
      setAiurl(resp.data.progressImageUrl)
      retdata = resp.data
      if (resp.data.progressImageUrl) {
        setloading(false)
      }
      // setNFTFormValue({
      //         ...NFTFormValue,
      //         ...{ ["NFTOrginalImage"]: resp.data.progressImageUrl},
      //       })
      console.log(progress)
    } while (Number(progress) != 100)

    return retdata;




  }
  {console.log("NFTFormValueNFTFormValue",NFTFormValue)}


  const imageStyle = {
    width: "100px", // Set the width of the cropped image
    height: "100px", // Set the height of the cropped image
    objectFit: "cover", // Adjust the object-fit property as needed
    clip: "rect(0px, 100px, 100px, 0px)", // Adjust the clip property to crop the desired area
  };


  return (
    <>
      <Header />
      <div className="create kitty__creatPage">

      <img src={require('../assets/images/kitty/aboutCloud.png')} className="img-fluid kitty__exploreCloud" />
<img src={require('../assets/images/kitty/hotloud.png')} className="kitty__hotCloud"/>
        <section className="tf-page-title">
          <div className="tf-container">
            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumbs" data-aos="fade-right">
                  <li>
                    <NavLink to="/">Home <MdKeyboardArrowRight/></NavLink>
                    {console.log('vallluuueeee', key, Value)}
                  </li>
                  <li>Create</li>
                </ul>
                {/* <h4 className="page-title-heading" data-aos="fade-right">
                  Create New NFT Here
                </h4> */}
              </div>
            </div>
          </div>
        </section>

        <section className="tf-add-nft">
          <div className="tf-container">
            <div className="row ">

              <div className="col-xl-10 col-lg-9 ">
                <div className="add-nft-inner">
                  <div className="hy">
                    {!accountAddress && (
                      <>
                        <h6 className="title bc" data-aos="fade-right">
                        Select Wallet
                        </h6>
                        <p className="sub" data-aos="fade-left">
                        Launch into the Naifty experience by linking your preferred wallet.
                        </p>
                        <ul className="blockchain-button" data-aos="fade-up">
                          <li>
                            <Link to="/connect">
                              <img src={MetaMask} alt="Image" />
                              MetaMask
                            </Link>
                          </li>
                          <li>
                            <Link to="/connect">
                              <img src={Wallet3} alt="Image" width={20} />
                              WalletConnect
                            </Link>
                          </li>
                          <li>
                            <Link to="/connect">
                              <img src={Wallet4} alt="Image" width={20} />
                              TrustWallet
                            </Link>
                          </li>
          
                        </ul>
                      </>
                    )}
                  </div>

                 

                  <div className="tf-tab">
                   
                    <div className="content-tab">
                      <div className="content-inner active d-block">
                        <div className="tab-create-collection">
                         
{/* 
                          <div className="d-flex align-items-center justify-content-between hy">
                            <div className="title_list">
                              <h6 className="title selectitem">Create Image</h6>
                              <p className="sub mb22">
                              Transform your ideas into digital art: Use our AI for instant creation or upload your own image to share with the world
                              </p>
                            </div>
                            <div className="d-flex ty">
                              <div className={checked ? 'switch_div d-flex switch_div_length switch_div_1 active_check' : 'switch_div d-flex switch_div_length switch_div_1'}>
                                <p className='pe-2 first_check'>Create</p>
                                <label className="switch">
                                  <input type="checkbox" role="switch" onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                                  <span className="slider round"></span>
                                </label>
                                <p className='ps-2 second_check'>Upload</p>
                              </div>
                            </div>
                          </div> */}



                          <div className="mt-4 mb-4">
                            {checked ?
                              <div>
                                <div className="row uploadlists">
                                  <div className="col-md-6 col-lg-8">
                                    <h6 className="title selectitem">Upload</h6>
                                    <p className="sub">
                                      PNG, GIF, WEBP or MP4. Max 50mb.
                                    </p>
                                    {(NFTFormValue?.NFTOrginalImage?.type?.includes("video") ||
                                      NFTFormValue?.NFTOrginalImage?.type?.includes("audio")
                                    ) ? (
                                      <div className="dropboxsplit">
                                        <Dropzone
                                          onDrop={(acceptedFiles) =>
                                            onChange(null, acceptedFiles, "Orginal")
                                          }
                                        >
                                          {({ getRootProps, getInputProps }) => (
                                            <div
                                              className="drag-upload"
                                              {...getRootProps()}
                                            >
                                              <input
                                                type="file"
                                                id="NFTOrginalImage"
                                                {...getInputProps()}
                                                autoComplete="off"
                                              />
                                              <img src={Upload} alt="Image" />
                                              <h6 className="upload-title">
                                                Upload File
                                              </h6>
                                              <h6 className="title">
                                                Drag your item to upload
                                              </h6>
                                              <p className="sub-title p-1">
                                                PNG, GIF, WebP, MP4 Or MP3. Maximum File
                                                Size 50 Mb.
                                              </p>
                                              {ValidateError.NFTOrginalImage && (
                                                <span className="text-danger img-file">
                                                  {ValidateError.NFTOrginalImage}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </Dropzone>
                                        <Dropzone
                                          onDrop={(acceptedFiles) =>
                                            onChange(null, acceptedFiles, "Thump")
                                          }
                                        >
                                          {({ getRootProps, getInputProps }) => (
                                            <div
                                              className="drag-upload"
                                              {...getRootProps()}
                                            >
                                              <input
                                                type="file"
                                                id="NFTThumpImage"
                                                accept="image/*"
                                                {...getInputProps()}
                                                autoComplete="off"
                                              />
                                              <img src={Upload} alt="Image" />
                                              <h6 className="title">
                                                Upload Thumbnail
                                              </h6>
                                              <h6 className="title">
                                                Drag your item to upload
                                              </h6>
                                              <p className="sub-title p-1">
                                                Image only. Maximum File
                                                Size 5 Mb.
                                              </p>
                                              {ValidateError.NFTThumpImage && (
                                                <span className="text-danger img-file">
                                                  {ValidateError.NFTThumpImage}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </Dropzone>
                                      </div>
                                    ) : (
                                      <Dropzone
                                        onDrop={(acceptedFiles) =>
                                          onChange(null, acceptedFiles, "Orginal")
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div
                                            className="drag-upload"
                                            {...getRootProps()}
                                          >

                                            <input
                                              type="file"
                                              id="NFTOrginalImage"
                                              {...getInputProps()}
                                              autoComplete="off"
                                            />
                                            <img src={Upload} alt="Image" />
                                            <h6 className="upload-title">
                                              Upload File
                                            </h6>
                                            <h6 className="title">
                                              Drag your item to upload
                                            </h6>
                                            <p className="sub-title p-1">
                                              PNG, GIF, WebP, MP4 Or MP3. Maximum File
                                              Size 50 Mb.
                                            </p>
                                            {ValidateError.NFTOrginalImage && (
                                              <span className="text-danger img-file">
                                                {ValidateError.NFTOrginalImage}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Dropzone>
                                    )}
                                  </div>
                                  <div className="col-md-6 col-lg-4">
                                    <div className="item-preview">
                                      <h5 className="title-preview"
                                      // data-aos="fade-left"
                                      >
                                        Item Preview
                                      </h5>
                                      {NFTFormValue.NFTOrginalImage != "" ?
                                        <div className="sc-product style1 px-2 py-3"
                                        // data-aos="zoom-in"
                                        >
                                          <div className="top">
                                            {/* {NFTFormValue.NFTName != "" ? (
                                              <Link to="#" className="tag">
                                                {NFTFormValue.NFTName}
                                              </Link>
                                            ) : (
                                              <Link to="#" className="tag">
                                                Sweet Baby #1
                                              </Link>
                                            )} */}

                                           
                                          </div>
                                          <div className="features">
                                            <div className="product-media">
                                              {NFTFormValue.NFTOrginalImage != "" ? (
                                                NFTFormValue?.NFTOrginalImage?.type?.includes(
                                                  "video"
                                                ) ? (
                                                  <video
                                                    loop={true}
                                                    muted
                                                    autoPlay={true}
                                                    controls
                                                    // onContextMenu="return false;"
                                                    type="video/*"
                                                    src={URL.createObjectURL(
                                                      NFTFormValue.NFTOrginalImage
                                                    )}
                                                    onError={(event) => {
                                                      event.target.src = URL.createObjectURL(
                                                        NFTFormValue.NFTOrginalImage
                                                      );
                                                    }}
                                                  ></video>
                                                ) : NFTFormValue?.NFTOrginalImage?.type?.includes(
                                                  "image"
                                                ) ? (
                                                  <img
                                                    src={URL.createObjectURL(
                                                      NFTFormValue.NFTOrginalImage
                                                    )}
                                                    alt="images"
                                                  />
                                                ) : NFTFormValue?.NFTOrginalImage?.type?.includes(
                                                  "audio") ? (
                                                  <>
                                                    {" "}
                                                    <audio
                                                      controls
                                                      // onContextMenu="return false;"
                                                      autoPlay={true}
                                                      loop={true}
                                                      muted
                                                      src={URL.createObjectURL(
                                                        NFTFormValue.NFTOrginalImage
                                                      )}
                                                      type="audio/*"
                                                    >
                                                    </audio>
                                                  </>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                <img
                                                  src={preview}
                                                  alt="images"
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div className="bottom">
                                            <div className="d-flex justify-content-between align-items-start w-100">

                                            <div className="top1">
                                              {NFTFormValue.NFTName != "" ? (
                                                <Link to="#" className="tag">
                                                  {NFTFormValue.NFTName.length > 10 ? <>{NFTFormValue.NFTName.slice(0, 10)}...</> : NFTFormValue.NFTName}
                                                </Link>
                                              ) : (
                                                <Link to="#" className="tag">
                                                 NFT Name
                                                </Link>
                                              )}
                                            </div>
                                            <div className="top">
                                            <div className="wish-list">
                                              <Link to="#" className="heart-icon"></Link>
                                            </div>
                                            </div>
                                            </div>
                                           
                                            <div className="details-product">
                                              <div className="author">
                                                <div className="avatar">
                                                  <img
                                                    src={payload?.Profile ? `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${payload?.Profile}` : config.profile}
                                                    alt="images"
                                                  />
                                                </div>
                                                <div className="content">
                                                  <div className="name name11">
                                                    {" "}
                                                    {payload ? <Link to="#">{payload?.DisplayName ? payload.DisplayName : accountAddress.slice(0, 4) + "..."}</Link> //Carly Webster
                                                      : <Link to="#">Carly Webster</Link>}
                                                  </div>
                                                </div>
                                              </div>

                                              
                                            </div>
                                            <div className="product-button d-flex justify-content-between align-items-center pur">
                                              {NFTFormValue.PutOnSale === false ?
                                                <div className="current-bid">
                                                  <div className="subtitle">
                                                    Auction
                                                  </div>
                                                  <div className="price">
                                                    <img src={endless} className="endlessimg" />
                                                  </div>
                                                </div>

                                                :
                                                <div className="current-bid">


                                                  <div className="subtitle">{NFTFormValue.PutOnSaleType === "FixedPrice" ? "Current price" : "Minimum bid"}</div>
                                                  <div className="price">
                                                    {NFTFormValue.PutOnSaleType === "FixedPrice"
                                                      ? <span className="cash text-white">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"}  {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "SOL"}</span>
                                                      : <span className="cash text-white">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"}  {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "SOL"}</span>}
                                                    <span className="icon ml-3">
                                                      <img src={ETH} alt="images" />
                                                    </span>
                                                  </div>

                                                </div>}

                                              <Link
                                                to="#"
                                                data-toggle="modal"
                                                data-target="#popup_bid"
                                                className="tf-button"
                                              >
                                                {" "}
                                                <span className="icon-btn-product"></span> Purchase
                                              </Link>


                                            </div>
                                          </div>
                                        </div>
                                        :
                                        <div className="sc-product style1 without_img_card"
                                        //  data-aos="zoom-in"
                                        >

                                          <div className="features">
                                            <div className="product-media">

                                              <img
                                                src={preview}
                                                alt="images"
                                              />

                                            </div>
                                          </div>

                                        </div>}

                                    </div>

                                    {NFTFormValue.NFTOrginalImage &&
                                      (NFTFormValue?.NFTOrginalImage?.type?.includes("video") ||
                                        NFTFormValue?.NFTOrginalImage?.type?.includes("audio")) && (
                                        <div className="thumbnail-preview">
                                          <h5 className="title-preview" data-aos="fade-right">
                                            Thumbnail Preview
                                          </h5>
                                          <div className="sc-product style1"
                                          // data-aos="zoom-in"
                                          >
                                            <div className="top">
                                             

                                              <div className="wish-list">
                                                <Link to="#" className="heart-icon"></Link>
                                              </div>
                                            </div>
                                            <div className="features">
                                              <div className="product-media">
                                                <img
                                                  src={
                                                    NFTFormValue.NFTThumpImage
                                                      ? URL.createObjectURL(
                                                        NFTFormValue.NFTThumpImage
                                                      )
                                                      : product52
                                                  }
                                                  alt="images"
                                                />
                                              </div>
                                            </div>
                                            <div className="bottom">
                                              <div className="top1">
                                                {NFTFormValue.NFTName != "" ? (
                                                  <Link to="#" className="tag">
                                                    {NFTFormValue.NFTName.length > 10 ? <>{NFTFormValue.NFTName.slice(0, 10)}...</> : NFTFormValue.NFTName}
                                                  </Link>
                                                ) : (
                                                  <Link to="#" className="tag">
                                                     NFT Name
                                                  </Link>
                                                )}
                                              </div>
                                              <div className="details-product">
                                                <div className="author">
                                                  <div className="avatar">
                                                   
                                                    <img
                                                      src={payload?.Profile ? `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${payload?.Profile}` : config.profile}
                                                      alt="images"
                                                    />
                                                  </div>
                                                  <div className="content">
                                                    <div className="name name11">
                                                      {" "}
                                                      {payload ? <Link to="#">{payload?.DisplayName ? payload.DisplayName : accountAddress.slice(0, 4) + "..."}</Link> //Carly Webster
                                                        : <Link to="#">Carly Webster</Link>}
                                                    </div>
                                                  </div>
                                                </div>


                                              </div>
                                              <div className="product-button d-flex justify-content-between align-items-center pur">
                                                {NFTFormValue.PutOnSale === false ?
                                                  <div className="current-bid">
                                                    <div className="subtitle">
                                                      Auction
                                                    </div>
                                                    <div className="price">
                                                      <img src={endless} className="endlessimg" />
                                                    </div>
                                                  </div>

                                                  :
                                                  <div className="current-bid">


                                                    <div className="subtitle">{NFTFormValue.PutOnSaleType === "FixedPrice" ? "Current price" : "Minimum bid"}</div>
                                                    <div className="price">
                                                      {NFTFormValue.PutOnSaleType === "FixedPrice"
                                                        ? <span className="cash text-white">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"} {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "SOL"}</span>
                                                        : <span className="cash text-white">{NFTFormValue?.NFTPrice ? NFTFormValue?.NFTPrice : "5"} {NFTFormValue?.CoinName ? NFTFormValue.CoinName : "SOL"}</span>}
                                                      <span className="icon  ml-3">
                                                        <img src={ETH} alt="images" />
                                                      </span>
                                                    </div>

                                                  </div>}

                                                <Link
                                                  to="#"
                                                  data-toggle="modal"
                                                  data-target="#popup_bid"
                                                  className="tf-button"
                                                >
                                                  {" "}
                                                  <span className="icon-btn-product"></span> Purchase
                                                </Link>


                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      )}

                                  </div>
                                </div>
                              </div>

                              :
                              <div className="aicard">
                                <div className="row">
                                  <div className="col-md-8">
                                    <h6 className="title selectitem">Create using AI</h6>
                                    <p className="sub mb22">
                                      Create an image from text prompt
                                    </p>

                                    <div className="prompt">
                                      <textarea
                                        onChange={(e) => setAitext(e.target.value)}
                                        className="textareass"
                                        placeholder="Enter Your Prompt Here"
                                        autoComplete="off"
                                      />
                                      {ValidateError.NFTOrginalImageai && (
                                        <span className="text-danger img-file">
                                          {ValidateError.NFTOrginalImageai}
                                        </span>
                                      )}
                                    </div>
                                    <div className="text-center mt-5">
                                      <button className="tf-button style-2 btn-1" disabled={loading} onClick={() => aicreate()}>Generate</button>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <h6 className="mb-4 mt-0 imgpreview">Image Preview</h6>
                                    {
                                      loading == true ?
                                       
                                        <>
                                        
                                          <div className="loading_parser">

                                            <CircularProgressbarWithChildren
                                              value={progressValue}
                                              text={`${progressValue}%`}
                                              strokeWidth={10}
                                              styles={buildStyles({
                                                strokeLinecap: "butt"
                                              })}
                                            >
                                              <RadialSeparators
                                                count={10}
                                                style={{
                                                  background: "#fff",
                                                  width: "2px",
                                                  // This needs to be equal to props.strokeWidth
                                                  height: `${10}%`
                                                }}
                                              />
                                            </CircularProgressbarWithChildren>
                                          </div>

                                          <p className="loading">Image is Processing please wait for sometime</p>
                                          <p className="loading" >It will come soon or more than a progress</p>
                                        </>
                                        :
                                        <>
                                          <img className="w-100" onContextMenu={preventContextMenu} src={aiurl ? aiurl : imageprev} alt="jj" />
                                          {loading ? <p>Please Wait Your image in process</p> : ""}
                                        </>
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                          {/* <fieldset>
                            {NFTFormValue?.Chain_ID &&
                              <>
                                <label className="mb8 selectitem1">Select Network</label>
                                <Select 
                                  value={{
                                    label: NFTFormValue?.Chain_ID === config.CHAIN_ID ? "ETH" : "BNB"  ,
                                    value: NFTFormValue?.Chain_ID === config.CHAIN_ID ? "ETH" : "BNB",
                                  }}
                                  onChange={(e) => setNetwork(e.value)
                                  }
                                  options={networks}
                                  classNamePrefix="react-select"
                                  isSearchable={false}
                                />
                              </>
                            }
                            {ValidateError.Category && (
                              <span className="text-danger img-file">
                                {ValidateError.Category}
                              </span>
                            )}
                          </fieldset> */}

                          <div className="">
                            <div className="d-flex align-items-center justify-content-between hy">
                              <div className="title_list">
                                <h6 className="title selectitem">List for sale Now</h6>
                                <p className="sub mb22">
                                  Your Item will be available to purchase
                                  immediately
                                </p>
                              </div>
                              <div className="toogle">
                                <div className="button-toggle">
                                  <input
                                    type="checkbox"
                                    id="switch4"
                                    checked={NFTFormValue.PutOnSale}
                                    onChange={() => {
                                      PutOnSale()
                                    }}
                                    autoComplete="off"
                                  />
                                  <label for="switch4"></label>
                                </div>
                              </div>
                            </div>
                            {console.log('dkshfkjdhkfgd', NFTFormValue)}
                            {NFTFormValue?.PutOnSale && (
                              <>
                                <div className="list_for_sale_now d-block d-sm-flex justify-content-sm-between hy">
                                  <div
                                    className={
                                      NFTFormValue?.PutOnSaleType ==
                                        "FixedPrice"
                                        ? "list_border active fixed_price d-flex justify-content-start align-items-center"
                                        : "list_border fixed_price d-flex justify-content-start align-items-center"
                                    }
                                    onClick={() =>
                                      setNFTFormValue({
                                        ...NFTFormValue,
                                        ...{
                                          ["PutOnSaleType"]: "FixedPrice",
                                        },
                                      })
                                    }
                                  >
                                    <div className="icon">
                                      <i
                                        class="fa fa-tag"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <span className="fixed">Fixed Price</span>
                                  </div>
                                  {String(NFTFormValue?.ContractType) === '721' &&
                                    <div
                                      className={
                                        NFTFormValue?.PutOnSaleType ==
                                          "TimedAuction"
                                          ? "list_border active timed_auction d-flex justify-content-start align-items-center"
                                          : "list_border timed_auction d-flex justify-content-start align-items-center"
                                      }
                                      onClick={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["PutOnSaleType"]: "TimedAuction",
                                          },
                                        })
                                      }
                                    >
                                      <div className="icon">
                                        <i
                                          class="fa fa-clock"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <span className="fixed">Timed Auction</span>
                                    </div>}
                                </div>

                                <div className="set-item1 my-5">
                                  <fieldset>
                                    <label className="mb8">
                                      {NFTFormValue.PutOnSaleType ==
                                        "FixedPrice"
                                        ? "Fixed Price"
                                        : "Minimum Bid"}
                                    </label>
                                    <div className="d-flex justify-content-between align-items-center fixedborder">
                                      <input
                                        type="text"
                                        className="set-items kitty__createInp"
                                        id="NFTPrice"
                                        onChange={onChange}
                                        value={NFTFormValue.NFTPrice}
                                        placeholder="e.g.10"
                                        autoComplete="off"
                                        maxLength={7}
                                      />
                                      <Select
                                        // menuIsOpen={true} 
                                        onChange={(e) =>
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{ ["CoinName"]: e.label },
                                          })
                                        }
                                        // value={
                                        //   NFTFormValue.PutOnSaleType
                                        //     && NFTFormValue.PutOnSaleType == "FixedPrice"
                                        //       ? {
                                        //         label: NFTFormValue.CoinName,
                                        //         value: NFTFormValue.CoinName,
                                        //       }
                                        //       : bnbcurrency.filter(
                                        //         (item) => item.value !== "BNB"
                                        //       )[0]

                                        // }
                                        // defaultValue={
                                        //   NFTFormValue.PutOnSaleType
                                        //     ? NFTFormValue.PutOnSaleType == "FixedPrice"
                                        //       ? {
                                        //         label: NFTFormValue.CoinName,
                                        //         value: NFTFormValue.CoinName,
                                        //       }
                                        //       : currency.filter(
                                        //         (item) => item.value !== "BNB"
                                        //       )[0]
                                        //     : currency[0]
                                        // }
                                        value={{ label: NFTFormValue?.CoinName, value: NFTFormValue?.CoinName }}
                                        options={
                                          NFTFormValue.PutOnSaleType ==
                                            "FixedPrice"
                                            ? currency?.filter(
                                              (item) => item.deleted != true
                                            )
                                            :
                                            currency?.filter(
                                              (item) => item.address != config.DEADADDRESS
                                            )

                                        }
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                      />
                                    </div>
                                    {console.log("jjjjjjjjjjjjjjj", NFTFormValue.PutOnSaleType ==
                                      "FixedPrice"
                                      ? currency?.filter(
                                        (item) => item.deleted != true
                                      )
                                      :
                                      currency?.filter(
                                        (item) => item.address != config.DEADADDRESS
                                      ), NFTFormValue.PutOnSaleType, currency
                                    )}
                                    {ValidateError.NFTPrice && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTPrice}
                                      </span>
                                    )}
                                    {ValidateError.CoinName && (
                                      <span className="text-danger img-file">
                                        {ValidateError.CoinName}
                                      </span>
                                    )}
                                  </fieldset>
                                  {NFTFormValue?.PutOnSaleType ==
                                    "FixedPrice" ? (
                                    <fieldset>
                                      <p className="mt-1">
                                        Service Fees{" "}
                                        {sellerFees}%
                                      </p>
                                      <p className="mt-1">
                                        You will receive {YouWillGet}{" "}
                                        {NFTFormValue.CoinName}
                                        {/* <i
                                          class="fa fa-usd"
                                          aria-hidden="true"
                                        ></i> */}
                                      </p>
                                    </fieldset>
                                  ) :
                                    collection == 1 && (
                                      <fieldset>
                                        <div className="my-3 d-block d-sm-flex align-items-center justify-content-sm-between">
                                          <div className="starting_date">
                                            <Select 
                                            // menuIsOpen={true}
                                              value={{
                                                value: NFTFormValue.ClockTime ? moment(NFTFormValue.ClockTime).format("YYYY-MM-DD h:mm:ss a") : "Please select Start Date",
                                                label: NFTFormValue.ClockTime ? moment(NFTFormValue.ClockTime).format("YYYY-MM-DD h:mm:ss a") : "Please select Start Date",
                                              }}
                                              onChange={(e) =>
                                                DateSelection(e, "start")
                                              }
                                              options={startingdate}
                                              classNamePrefix="react-select"
                                              isSearchable={false}
                                            />
                                            {ValidateError.ClockTime && (
                                              <span className="text-danger img-file">
                                                {ValidateError.ClockTime}
                                              </span>
                                            )}
                                          </div>
                                          <div className="ending_date">
                                            <Select 
                                            // menuIsOpen={true}
                                              value={{
                                                value: NFTFormValue.EndClockTime ? moment(NFTFormValue.EndClockTime).format("YYYY-MM-DD h:mm:ss a") : "Please select End Date"
                                                ,
                                                label:
                                                  NFTFormValue.EndClockTime ? moment(NFTFormValue.EndClockTime).format("YYYY-MM-DD h:mm:ss a") : "Please select End Date",
                                              }}
                                              onChange={(e) =>
                                                DateSelection(e, "end")
                                              }
                                              options={enddate}
                                              classNamePrefix="react-select"
                                              isSearchable={false}
                                            />
                                            {ValidateError.EndClockTime && (
                                              <span className="text-danger img-file">
                                                {ValidateError.EndClockTime}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </fieldset>
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                          {collection == 1 ? (
                            <>
                              {/* <fieldset>
                             <label className="mb8 selectitem1">Select Network</label>
                             <Select
                               // isDisabled={
                               //   NFTFormValue.CollectionSymbol ? true : false
                               // }
                               value={{
                                 label: NFTFormValue?.Chain_ID == '97' ? "BNB" : "ETH",
                                 value: NFTFormValue?.Chain_ID == '97' ? "BNB" : "ETH",
                               }}
                               // defaultValue={NFTFormValue.PutOnSaleType == "FixedPrice" ?  currency[0] : currency[1]}
                               onChange={(e) => setNetwork(e.value)
                                 // setNFTFormValue({
                                 //   ...NFTFormValue,
                                 //   ...{ ["Category"]: e.label },
                                 // })
                               }
                               options={networks}
                               classNamePrefix="react-select"
                               isSearchable={false}
                             />
                             {ValidateError.Category && (
                               <span className="text-danger img-file">
                                 {ValidateError.Category}
                               </span>
                             )}
                           </fieldset> */}
                              <fieldset>
                                <label className="selectitem1">Name of your item</label>
                                <input
                                  type="text"
                                  onChange={onChange}
                                  id="NFTName"
                                  placeholder="Name your item"
                                  value={NFTFormValue.NFTName}
                                  autoComplete="off"
                                  className="kitty__createInp"
                                />
                                {ValidateError.NFTName && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTName}
                                  </span>
                                )}
                              </fieldset>
                            </>
                          ) : (
                            <div className="set-item">
                              <fieldset>
                                <label className="mb8">Name your item</label>
                                <input
                                  type="text"
                                  onChange={onChange}
                                  id="NFTName"
                                  value={NFTFormValue.NFTName}
                                  placeholder="Name your item"
                                  autoComplete="off"
                                  className="kitty__createInp"
                                />
                                {ValidateError.NFTName && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTName}
                                  </span>
                                )}
                              </fieldset>
                              <fieldset>
                                <label className="mb8">No of Copy</label>
                                <input
                                  type="text"
                                  onChange={onChange}
                                  id="NFTQuantity"
                                  value={NFTFormValue.NFTQuantity}
                                  placeholder="No of Copy"
                                  autoComplete="off"
                                  maxLength={8}
                                />
                                {ValidateError.NFTQuantity && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTQuantity}
                                  </span>
                                )}
                              </fieldset>
                            </div>
                          )}

                          <fieldset>
                            <label className="selectitem1">Enter Short Description</label>
                            {/* <input
                                type="text"
                                onChange={onChange}
                                id="NFTDescription"
                                value={NFTFormValue.NFTDescription}
                                placeholder="Enter short description"
                                autoComplete="off" 
                              /> */}
                            <textarea
                              // type="text"
                              className="textareass kitty__createInp"
                              onChange={onChange}
                              id="NFTDescription"
                              value={NFTFormValue.NFTDescription}
                              // placeholder="Enter short description"
                              autoComplete="off"
                            />
                          </fieldset>

                          {/* <fieldset className="propertise">
                              <label className="mb8">Add properties</label>
                              <input
                                type="key"
                                onChange={Check}
                                id="Properties"
                                // value={NFTFormValue.NFTDescription}
                                placeholder="property Key"
                                autoComplete="off" 
                              />
                              <input
                                type="value"
                                onChange={Check}
                                id="Properties"
                                // value={NFTFormValue.NFTDescription}
                                placeholder="property value"
                                autoComplete="off" 
                              />
                              <ul className="propertise-list">
                                <li><Link to="#">Art<i className="fal fa-times"></i></Link></li>
                                <li><Link to="#">Body type<i className="fal fa-times"></i></Link></li>
                                <li><Link to="#">Face color<i className="fal fa-times"></i></Link></li>
                              </ul>
                            </fieldset> */}

                          <div className="set-item">
                            <fieldset>
                              <label className="mb8 selectitem1">Set Category</label>
                              <Select
                                isDisabled={
                                  NFTFormValue.CollectionSymbol ? true : false
                                }
                                value={{
                                  label: NFTFormValue?.Category,
                                  value: NFTFormValue?.Category,
                                }}
                                // defaultValue={NFTFormValue.PutOnSaleType == "FixedPrice" ?  currency[0] : currency[1]}
                                onChange={(e) =>
                                  setNFTFormValue({
                                    ...NFTFormValue,
                                    ...{ ["Category"]: e.label },
                                  })
                                }
                                options={Categorys}
                                classNamePrefix="react-select"
                                isSearchable={false}
                              />
                              {ValidateError.Category && (
                                <span className="text-danger img-file">
                                  {ValidateError.Category}
                                </span>
                              )}
                            </fieldset>
                            <fieldset>
                              <label className="mb8 selectitem1">
                                Set Royalty%
                              </label>
                              <input className="kitty__createInp"
                                type="text"
                                onChange={onChange}
                                id="NFTRoyalty"
                                placeholder="E.G. 5"
                                value={NFTFormValue.NFTRoyalty}
                                autoComplete="off"
                              />
                              {ValidateError.NFTRoyalty && (
                                <span className="text-danger img-file">
                                  {ValidateError.NFTRoyalty}
                                </span>
                              )}
                            </fieldset>
                          </div>
                          <fieldset>
                            <label>Traits</label>
                            <div className="d-flex align-items-center justify-content-start text-white rootProperty">
                            Traits describe attributes of your item. They appear as filters inside your collection page and are also listed out inside your item page.
                              <div className="d-flex align-items-center justify-content-between tab-details">
                                <ul class="properties">
                                  {number.map((val, index) =>
                                    <> {(number.length - 2 >= index) &&
                                      <li>
                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 11.375L1.09375 7.53125L0 8.375L6 13.0312L12 8.375L10.9062 7.53125L6 11.375ZM6 9.65625L10.9062 5.84375L12 5L6 0.34375L0 5L1.09375 5.84375L6 9.65625ZM6 2.03125L9.8125 5L6 7.96875L2.1875 5L6 2.03125Z" fill="white">
                                          </path>
                                        </svg>{key[index]} : {Value[index]}
                                        <i onClick={() => RemoveProperties(index)} className="fa fa-times"></i>
                                      </li>}
                                    </>)}
                                </ul>
                              </div>
                            </div>

                            <>
                              {/* <div className="d-flex align-items-center justify-content-between">
                              <input
                                type="text"
                                id="key"
                                // value={NFTFormValue.NFTDescription}
                                placeholder="Color"
                                autoComplete="off"
                                style={{width:"48%"}} 
                                onChange={(e)=>Properties(e,index)}
                              />
                              <input
                                type="text"
                                id="value"
                                // value={NFTFormValue.NFTDescription}
                                placeholder="Red"
                                autoComplete="off" 
                                style={{width:"48%"}}
                                onChange={(e)=>Properties(e,index)}
                              />
                              </div> */}
                              <div className="row">
                                <div className="col-md-12">

                                  <div className="row mb-3 align-items-end upper__indexing">
                                    <div className="col-md-5">
                                      <div className="mt-3" style={{ marginTop: 30 }}>
                                        {/* <label>Color (optional)</label> */}
                                        <input id="key" type="text" className="kitty__createInp" placeholder="Color (optional)" onChange={(e) => Properties(e)} />                                       
                                      </div>
                                      {ValidateError.key !== undefined && (
                                    <span className="text-danger mt-2 ml-3 img-file">
                                      {ValidateError.key}
                                    </span>
                                  )}
                                    </div>
                                    <div className="col-md-5">
                                      <div className="mt-3" style={{ marginTop: 30 }}>
                                      {/* <label>Size (optional)</label> */}
                                        <input id="value" type="text" className="kitty__createInp" placeholder="Size (optional)" onChange={(e) => Properties(e)} />                                     
                                      </div>
                                      {ValidateError.value !== undefined && (
                                    <span className="text-danger mt-2 ml-3 img-file">
                                      {ValidateError.value}
                                    </span>
                                  )}
                                    </div>
                                    <div className="col-md-2">
                                      <div style={{ marginTop: 30 }} className="button_add_more">
                                        <button type="button" class="tf-button kitty__loadMoreBtn" onClick={AddProperties}>Add</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>

                              </div>

                              {/* <div className="d-flex align-items-center justify-content-between my-4">
                                <div className="w-100 text-align-center mb-2">
                                  {ValidateError.key !== undefined && (
                                    <span className="text-danger img-file">
                                      {ValidateError.key}
                                    </span>
                                  )}
                                </div>
                                <div className="w-100 text-align-center mb-2 ml-5">
                                  {ValidateError.value !== undefined && (
                                    <span className="text-danger img-file">
                                      {ValidateError.value}
                                    </span>
                                  )}
                                </div>
                              </div> */}

                            </>
                          </fieldset>
                          {/* <h6 className="title ">Choose Collection</h6>
                            <p className="sub">
                              They All Serve The Same Purpose.
                            </p>
                            <ul className="create-collection">
                              <li
                                className=""
                                onClick={() => WalletCheck()}
                              >
                                <div className="create-item">
                                  <div className="img">
                                    <i className="fal fa-plus"></i>
                                  </div>
                                  <div className="content">
                                    <h6>Create new collection</h6>
                                    <p>Tap to create</p>
                                  </div>
                                </div>
                              </li>
                              {CreateCollectionState?.map((item, index) => {
                                return (
                                  <li className={NFTFormValue.CollectionSymbol===item.CollectionSymbol?"active":""}
                                    onClick={() => ChooseCollection(item.CollectionSymbol,item.Category)}
                                    key={Math.random()}
                                  >
                                    <div className="create-item">
                                      <div className="img">
                                        <img
                                          src={
                                            item.CollectionProfileImage
                                              ? `${config.IMG_URL}/collection/${item.CollectionSymbol}/${item.CollectionProfileImage}`
                                              : require("../assets/images/add-collection.jpg")
                                          }
                                          alt="Image"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>{item.CollectionName}</h6>
                                        <p>{item.CollectionCount} items</p>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul> */}



                          {/* <ul className="collection-list">
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Product updates</p>
                                    <h6>Receive messages from our platform</h6>
                                  </div>

                                  <div className="button-toggle">
                                  <input type="checkbox" id="switch1" checked={NFTFormValue.isMessageapprove}
                                      onChange={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["isMessageapprove"]:
                                              !NFTFormValue.isMessageapprove,
                                          },
                                        })
                                      }/>
                                    <label for="switch1"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Reminders</p>
                                    <h6>
                                      Receive booking reminders, pricing notices
                                    </h6>
                                  </div>
                                  <div className="button-toggle mt0">
                                  <input type="checkbox" id="switch5" checked={NFTFormValue.isPricenotification}
                                      onChange={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["isPricenotification"]:
                                              !NFTFormValue.isPricenotification,
                                          },
                                        })
                                      }/>
                                    <label for="switch5"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Promotions and tips</p>
                                    <h6>
                                      Receive coupons, promotions, surveys
                                    </h6>
                                  </div>
                                  <div className="button-toggle">
                                  <input type="checkbox" id="switch6"  checked={NFTFormValue.isPromotion}
                                      onChange={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["isPromotion"]:
                                              !NFTFormValue.isPromotion,
                                          },
                                        })
                                        }/>
                                    <label for="switch6"></label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="list">
                                  <div className="infor">
                                    <p>Account support</p>
                                    <h6>
                                      Receive messages about your account, your
                                      trips, legal alerts
                                    </h6>
                                  </div>
                                  <div className="button-toggle">
                                  <input type="checkbox" id="switch7" checked={NFTFormValue.islegalalert}
                                      onChange={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["islegalalert"]:
                                              !NFTFormValue.islegalalert,
                                          },
                                        })
                                        }/>
                                    <label for="switch7"></label>
                                  </div>
                                </div>
                              </li>
                            </ul> */}

                          <div className="bottom-button hj">
                            <Button
                              className="tf-button kitty__loadMoreBtn kitty__loadMorePad"
                              disabled={
                                FormButton == "error" ||
                                  FormButton == "done" ||
                                  FormButton == "process"
                                  ? true
                                  : false
                              }
                              onClick={
                                FormButton == "start" ? FormSubmit : null
                              }
                            >
                              {FormButton == "start" && "Publish"}
                              {FormButton == "error" && "Publish"}
                              {FormButton == "process" && "processing"}
                              {FormButton == "done" && "Done"}
                            </Button>
                            <Button className="kitty__secondaryBtn" onClick={() => DiscardAll()}>
                              Discard all
                            </Button>
                            {/* <Link to="#" className="tf-button active">Publish</Link>
                                                        <Link to="#" className="tf-button">Discard all</Link> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          collection == 3
                            ? "content-inner active d-block"
                            : "content-inner d-none"
                        }
                      >
                        <div className="tab-create-item">
                          <h6 className="title">Upload An Item</h6>
                          <p className="sub">
                            But Each One Takes A Different Approach And Makes
                            Different Tradeoffs.
                          </p>
                          {console.log("NFTFormValue?.Chain_ID",NFTFormValue?.Chain_ID)}
                          <div className="drag-upload">
                            <input type="file" />
                            <img src={Upload} alt="Image" />
                            <h6 className="title">
                              Drag your item to upload
                            </h6>
                            <p className="sub-title">
                              PNG, GIF, WebP, MP4 Or MP3. Maximum File Size
                              100 Mb.
                            </p>
                          </div>

                          <h6 className="title">Upload An Item</h6>
                          <p className="sub mb22">
                            But Each One Takes A Different Approach And Makes
                            Different Tradeoffs.
                          </p>
                         
                          <fieldset>
                            <label>Name of your item</label>
                            <input type="text" placeholder="Name your item" className="kitty__createInp" value={NFTFormValue.NFTName} autoComplete="off" />
                          </fieldset>

                          <fieldset>
                            <label>Enter short description</label>
                            <input
                              type="text" className="kitty__createInp"
                              placeholder="Enter short description"
                              value={NFTFormValue.NFTDescription}
                              autoComplete="off"
                            />
                          </fieldset>

                          {/* <fieldset className="propertise">
                                                    <label className="mb8">Add properties</label>
                                                    <ul className="propertise-list">
                                                        <li><Link to="#">Art<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Body type<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Face color<i className="fal fa-times"></i></Link></li>
                                                    </ul>
                                                </fieldset> */}

                          <div className="set-item">
                            <fieldset>
                              <label className="mb8">
                                Set item price or starting bid
                              </label>
                              <input type="text" placeholder="eg. 0,01 Eth" autoComplete="off" />
                            </fieldset>
                            <fieldset>
                              <label className="mb8">
                                Select royalties amount, %
                              </label>
                              <input type="text" placeholder="eg. 5%,10%,15%" autoComplete="off" />
                            </fieldset>
                          </div>

                          <h6 className="title ">Choose Collection</h6>
                          <p className="sub">
                            They All Serve The Same Purpose.
                          </p>

                          <ul className="create-collection">
                            <li className="">
                              <div className="create-item">
                                <div className="img">
                                  <i className="fal fa-plus"></i>
                                </div>
                                <div className="content">
                                  <h6>Create new collection</h6>
                                  <p>Tap to create</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="create-item">
                                <div className="img">
                                  <img
                                    src={require("../assets/images/add-collection.jpg")}
                                    alt="Image"
                                  />
                                </div>
                                <div className="content">
                                  <h6>Battle for Digital</h6>
                                  <p>56 items</p>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <h6 className="title mb0">Choose collection</h6>
                          <p className="sub mb20">
                            They all serve the same purpose.
                          </p>

                          <ul className="collection-list">
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Product updates</p>
                                  <h6>Receive messages from our platform</h6>
                                </div>

                                <div className="button-toggle">
                                  <input type="checkbox" id="switch" />
                                  <label for="switch"></label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Reminders</p>
                                  <h6>
                                    Receive booking reminders, pricing notices
                                  </h6>
                                </div>
                                <div className="button-toggle mt0">
                                  <input type="checkbox" id="switch1" />
                                  <label for="switch1"></label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Promotions and tips</p>
                                  <h6>
                                    Receive coupons, promotions, surveys
                                  </h6>
                                </div>
                                <div className="button-toggle">
                                  <input type="checkbox" id="switch2" />
                                  <label for="switch2"></label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Account support</p>
                                  <h6>
                                    Receive messages about your account, your
                                    trips, legal alerts
                                  </h6>
                                </div>
                                <div className="button-toggle">
                                  <input type="checkbox" id="switch3" />
                                  <label for="switch3"></label>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <div className="bottom-button">
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          collection == 4
                            ? "content-inner active d-block"
                            : "content-inner d-none"
                        }
                      >
                        <div className="tab-create-collection">
                          <h6 className="title">Upload </h6>
                          <p className="sub">
                            But Each One Takes A Different Approach And Makes
                            Different Tradeoffs.
                          </p>

                          <div className="drag-upload">
                            <input type="file" />
                            <img src={Upload} alt="Image" />
                            <h6 className="title">
                              Drag your item to upload
                            </h6>
                            <p className="sub-title">
                              PNG, GIF, WebP, MP4 Or MP3. Maximum File Size
                              100 Mb.
                            </p>
                          </div>

                          <h6 className="title">Upload An Item</h6>
                          <p className="sub mb22">
                            But Each One Takes A Different Approach And Makes
                            Different Tradeoffs.
                          </p>
                          
                          <fieldset>
                            <label>Name  of your item</label>
                            <input type="text" placeholder="Name your item" className="kitty__createInp" autoComplete="off" />
                          </fieldset>

                          <fieldset>
                            <label>Enter short description</label>
                            <input
                              type="text"
                              placeholder="Enter short description"
                              autoComplete="off"
                              className="kitty__createInp"
                            />
                          </fieldset>

                          {/* <fieldset className="propertise">
                                                    <label className="mb8">Add properties</label>
                                                    <ul className="propertise-list">
                                                        <li><Link to="#">Art<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Body type<i className="fal fa-times"></i></Link></li>
                                                        <li><Link to="#">Face color<i className="fal fa-times"></i></Link></li>
                                                    </ul>
                                                </fieldset> */}

                          <div className="set-item">
                            <fieldset>
                              <label className="mb8">
                                Set item price or starting bid
                              </label>
                              <input
                                type="text"
                                placeholder="E.G. 0,01 Eth"
                                autoComplete="off"
                              />
                            </fieldset>
                            <fieldset>
                              <label className="mb8">
                                Set Royalty %
                              </label>
                              <input className="kitty__createInp"
                                type="text"
                                placeholder="E.G. 0,01 Eth"
                                autoComplete="off"
                              />
                            </fieldset>
                          </div>

                          <h6 className="title ">Choose Collection</h6>
                          <p className="sub">
                            They All Serve The Same Purpose.
                          </p>

                          <ul className="create-collection">
                            <li className="">
                              <div className="create-item">
                                <div className="img">
                                  <i className="fal fa-plus"></i>
                                </div>
                                <div className="content">
                                  <h6>Create new collection</h6>
                                  <p>Tap to create</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="create-item">
                                <div className="img">
                                  <img
                                    src={require("../assets/images/add-collection.jpg")}
                                    alt="Image"
                                  />
                                </div>
                                <div className="content">
                                  <h6>Battle for Digital</h6>
                                  <p>56 items</p>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <h6 className="title mb0">Choose collection</h6>
                          <p className="sub mb20">
                            They all serve the same purpose.
                          </p>

                          <ul className="collection-list">
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Product updates</p>
                                  <h6>Receive messages from our platform</h6>
                                </div>

                                <div className="button-toggle">
                                  <input type="checkbox" id="switch4" />
                                  <label for="switch4"></label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Reminders</p>
                                  <h6>
                                    Receive booking reminders, pricing notices
                                  </h6>
                                </div>
                                <div className="button-toggle mt0">
                                  <input type="checkbox" id="switch5" />
                                  <label for="switch5"></label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Promotions and tips</p>
                                  <h6>
                                    Receive coupons, promotions, surveys
                                  </h6>
                                </div>
                                <div className="button-toggle">
                                  <input type="checkbox" id="switch6" />
                                  <label for="switch6"></label>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="list">
                                <div className="infor">
                                  <p>Account support</p>
                                  <h6>
                                    Receive messages about your account, your
                                    trips, legal alerts
                                  </h6>
                                </div>
                                <div className="button-toggle">
                                  <input type="checkbox" id="switch7" />
                                  <label for="switch7"></label>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <div className="bottom-button">
                            <Link to="#" className="tf-button active">
                              Publish
                            </Link>
                            <Link to="#" className="tf-button">
                              Discard all
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>






            </div>
          </div>
        </section>

      </div>
      <Footer />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show8}
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose8}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
            Follow Steps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ApproveButton != "stop" && (
            <div className="ifpsmetadata mt-4">
              <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
                <i className="fa fa-check"></i>
                <div className="text-left ml-5">
                  <h6 className="receives">Approve Call</h6>
                  <p className="receives">One Time Process</p>
                </div>
              </div>
              <Button
                className="connectwallet primary my-3"
                disabled={
                  ApproveButton == "process" || ApproveButton == "done"
                    ? true
                    : false
                }
                onClick={
                  ApproveButton == "start" || ApproveButton == "try"
                    ? TokenApproveCall
                    : null
                }
                disableRipple
              >
                {ApproveButton == "start" && "Start"}
                {ApproveButton == "process" && "In-Progress"}
                {ApproveButton == "try" && "Try-Again"}
                {ApproveButton == "done" && "Done"}
              </Button>
            </div>
          )}

          <div className="ifpsmetadata">
            <div className="ifpsmetadatasec  d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6 className="receives">IPFS Metadata</h6>
                <p className="receives">Generates IPFS Metadata for your NFT</p>
              </div>
            </div>
            <Button
              className="connectwallet primary my-3"
              disabled={
                UploadButton == "process" || UploadButton == "done" || UploadButton == "stop"
                  ? true
                  : false
              }
              onClick={
                UploadButton == "start" || UploadButton == "try"
                  ? UploadIPFScall
                  : null
              }
              disableRipple
            >
              {UploadButton == "stop" && "Start"}
              {UploadButton == "start" && "Start"}
              {UploadButton == "process" && "In-Progress"}
              {UploadButton == "try" && "Try-Again"}
              {UploadButton == "done" && "Done"}
            </Button>
          </div>

          <div className="ifpsmetadata mt-4">
            <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6 className="receives">Mint NFT</h6>
                <p className="receives">Call contract method</p>
              </div>
            </div>
            <Button
              className="connectwallet primary my-3"
              disabled={(MintButton === "stop" || MintButton == "process" || MintButton == "done") ? true : false}
              onClick={MintButton == "start" || MintButton == "try"
                ? MintCall
                : null}
              disableRipple
            >
              {MintButton == "stop" && "Start"}
              {MintButton == "start" && "Start"}
              {MintButton == "process" && "In-Progress"}
              {MintButton == "try" && "Try-Again"}
              {MintButton == "done" && "Minted"}
            </Button>
         
          </div>
  
        </Modal.Body>
      </Modal>
      {OpenPopup == "collection" && (
        <CreateCollection
          SetOpenPopup={SetOpenPopup}
          Creator={accountAddress}
          Network={NFTFormValue?.CollectionNetwork}
          Type={NFTFormValue?.ContractType}
          Address={NFTFormValue?.ContractAddress}
          GetCollection={() => CollectionByCreate({
            Type: collection == 1 ? 721 : 1155,
            Creator: accountAddress
          })}
          collection={collection}
          AccountAddress={accountAddress}
        />
      )}
      {((OpenPopup == "ClockTime" ||
        OpenPopup == "EndClockTime") && (
          <CLockModal
            page={"create"}
            SetOpenPopup={SetOpenPopup}
            modal={OpenPopup}
            validDate={OpenPopup == "ClockTime" ? validStart : validEnd}
            setClockValue={setClockValue}
            closeModal={CloseModal}
          />
        ))}
    </>
  );
}
