import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation,useParams } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import config from './config/config'
import faqimg from "../assets/images/galaxy-7040416__480.webp";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { getarticle } from "../actions/axioss/cms.axios.js";
export default function Blogdetail() {
    const location = useLocation();
    const {url} = useParams()
    console.log("DDDDDD", location, location?.state, "location",url)
    const [blog, setBlog] = useState({})

    useEffect(() => {
        if(location?.state?.item){
            setBlog(location?.state?.item)
        }
        if(url){
            blogdata(url)
        }
        
    }, [])

    const blogdata=async(data)=>{
        var resp=await getarticle({url : data});
        if(resp.status){
            setBlog(resp?.data)
        }
    }



    return (
        <>

            <Header />

            <div className="blog blogdetail">
             <img src={require('../assets/images/kitty/aboutCloud.png')} className="img-fluid kitty__exploreCloud" />
                <section className="tf-page-title style-2" data-aos="fade-right">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight/></NavLink></li>
                                    <li>Blogdetail</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="tf-faq">
                    <div className="tf-container">
                        <div className="blogd">
                            <h5 className="mb-2" data-aos="fade-down">{blog?.heading}</h5>
                            <p className="grays mb-4">Published By {blog?.date && `${new Date(blog?.date).toLocaleString('default', { month: 'long' })} ${new Date(blog?.date).getDate()},${new Date(blog?.date).getFullYear()}`}</p>
                            <div className="text-centers">
                                <img class="blogdetailimg mt-3" src={`${config.IMG_URL}/${blog?.img}`} alt="Card image cap" />
                            </div>
                            <p className="pt-4" data-aos="fade-down"><div
                                dangerouslySetInnerHTML={{ __html: blog?.content }}
                            />
                            </p>
                        </div>

                    </div>
                </section >
            </div>

            <Footer />
        </>
    )
}







